import React, { useState } from "react";
import axios from "../../libs/axios";
import { Formik, Form } from "formik";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import "../Assesment/assesment.css";
import { Button } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default function ManagementPractice({
  formData,
  handlePreviousPage,
  previouscallingBackend,
  setPreviousCallingBackend,
  handleNextPage,
  callingBackend,
  setCallingBackend,
}) {
  const { state } = useLocation();
  const typeOptions = [
    { name: "Adequate", value: 1 },
    { name: "Improvement Needed", value: 0.92 },
    {
      name: "Inadequate",
      value: 0.86,
    },
    { name: "N/A", value: 1 },
  ];
  const practice = [
    {
      id: 0,
      name: "Has a Hot Work Permit with a 60-minute firewatch and a further 3-hour monitoring period been instated on site?",
      pdfname: "Hot Work",
      type: { name: "", value: 1 },
      ri: 2,
      description: "",
    },
    {
      id: 1,
      name: "Has a Contractor Management Policy with a Visitor Induction been instated on site?",
      pdfname: "Contractor Management",
      type: { name: "", value: 1 },
      ri: 3,
      description: "",
    },
    {
      id: 2,
      name: "Is there a Smoking Policy in place?Is there a Smoking Policy in place?",
      pdfname: "Smoking PolicySmoking Policy",
      type: { name: "", value: 1 },
      ri: 5,
      description: "",
    },
    {
      id: 3,
      name: "Is the site's General Housekeeping adequate for the occupancy?",
      pdfname: "General Housekeeping",
      type: { name: "", value: 1 },
      ri: 6,
      description: "",
    },
    {
      id: 4,
      name: "Are all electrical switch-rooms and plant rooms kept clean, cooled and sealed with smoke detection?",
      pdfname: "Electrical-Switchrooms and Plant Rooms",
      type: { name: "", value: 1 },
      ri: 7,
      description: "",
    },
    {
      id: 5,
      name: "Does the site house Critical Spares in-line with their Preventative Maintenance Programs, Loss Prevention Programs and Culture?",
      pdfname: "Culture, Preventative Maintenance and Critical Spares",
      type: { name: "", value: 1 },
      ri: 8,
      description: "",
    },
    {
      id: 6,
      name: "Is Thermographic Scanning carried out on main electrical switchboards?Is Thermographic Scanning carried out on main electrical switchboards?",
      pdfname: "Preventative Electrical Maintenance",
      type: { name: "", value: 1 },
      ri: 9,
      description: "",
    },
    {
      id: 7,
      name: "Is a fire protection equipment impairment management program in use?",
      pdfname: "Impairment Management",
      type: { name: "", value: 1 },
      ri: 1,
      description: "",
    },
    {
      id: 8,
      name: "Has an Emergency Response Plan been instated on site?",
      pdfname: "Emergency Response",
      type: { name: "", value: 1 },
      ri: 22,
      description: "",
    },
    {
      id: 9,
      name: "Has the organisation instated a Business Continuity Plan with annual scenario testing?",
      pdfname: "Business Continuity Planning",
      type: { name: "", value: 1 },
      ri: 34,
      description: "",
    },
  ];

const toolbarOptions = [
  ["bold", "italic", "underline"],
  [{ header: 1 }, { header: 2 }],
  [{ list: "ordered" }, { list: "bullet" }],
  [{ script: "sub" }, { script: "super" }],
];
  const module = {
    toolbar: toolbarOptions,
  };

  const [arr, setArr] = useState(
    formData?.management_practice?.m_practice?.length
      ? [...formData?.management_practice?.m_practice]
      : practice
  );
  const changeFormData = (value, name, id) => {
    console?.log("id", id);
    const objIndex = arr?.findIndex((obj) => obj?.id === id);
    arr[objIndex][name] = value;

    setArr([...arr]);
  };
  const changeCommentData = (value, name, id) => {
    const objIndex = arr?.findIndex((obj) => obj?.id === id);
    arr[objIndex][name] = value;

    setArr([...arr]);
  };
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const submit = async (e) => {
    const availableScore = arr?.reduce(
      (n, { type }) => n * Number(type?.value),
      1
    );
    console?.log("type", availableScore * 30);
    try {
      setCallingBackend(true);
      await axios.put(`/assesment/management_practice/${state?.id}`, {
        m_practice: arr,
        available_score: Math?.round(availableScore * 30),
      });
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Management practice one created successfully!",
      });
      setTimeout(
        () =>
          setAlert({
            showAlert: false,
            severity: "success",
            message: "Management practice one created successfully!",
          }),
        handleNextPage(),
        5000
      );
    } catch (error) {
      if (error?.response?.data === "Management practice one already exists") {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Management practice one already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Management practice one already exists!",
            }),
          5000
        );
      } else if (error?.response?.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Unauthorized Access!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Unauthorized Access!",
            }),
          5000
        );
      } else if (error?.response?.status === 500) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Server Error!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Server Error!",
            }),
          5000
        );
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Management practice one already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Management practice one already exists!",
            }),
          5000
        );
      }
    }
  };

  const handleClick = () => {
    setPreviousCallingBackend(true);
    handlePreviousPage();
  };

  return (
    <div>
      <Formik
        initialValues={practice}
        onSubmit={submit}
        // validationSchema={validationSchema}
        enableReinitialize
      >
        {({ dirty, isValid }) => {
          return (
            <Form>
              <Grid
                container
                xs={12}
                sx={{
                  padding: "1rem",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="formName">PRS AREA - Management Practices</p>
                <Grid container xs={10} spacing={1}>
                  {arr?.map((item, i) => {
                    return (
                      <>
                        <Grid item xs={12} sx={{ marginTop: "1?.5rem" }}>
                          <p className="font">{item?.name}</p>
                          <Autocomplete
                            hiddenLabel
                            id="type"
                            name="type"
                            size="small"
                            sx={{ marginBottom: "5px" }}
                            value={item?.type}
                            options={typeOptions}
                            getOptionLabel={(option) => option?.name}
                            onChange={(event, newValue) => {
                              changeFormData(newValue, "type", item?.id);
                            }}
                            renderInput={(params) => (
                              <TextField size="small" {...params} />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sx={{ marginBottom: "50px" }}>
                          <ReactQuill
                            modules={module}
                            theme="snow"
                            value={item?.description}
                            style={{
                              marginBottom: "25px",
                              height: "100px",
                            }}
                            onChange={(e) => {
                              changeCommentData(e, "description", item?.id);
                            }}
                          />
                          {/* <InputFeild
                            name="description"
                            value={item?.description}
                            Placeholder={"Enter up to 200 words"}
                            rows={3}
                            multiline
                            onChange={(e) =>
                              changeFormData(
                                e?.target?.value,
                                "description",
                                item?.id
                              )
                            }
                            component={TextField}
                            variant
                            fullWidth
                            bgColor="#FFFFFF"
                            margin="dense"
                          />{" "} */}
                        </Grid>
                      </>
                    );
                  })}
                  <Grid
                    container
                    item
                    xs={12}
                    sx={{
                      alignContent: "center",
                      justifyContent: "space-between",
                      marginTop: "2rem",
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => handleClick()}
                      sx={{
                        backgroundColor: "#22345B",
                        textTransform: "capitalize",
                        fontFamily: "serif",
                        borderRadius: "8px",
                        ":hover": { backgroundColor: "#22345B" },
                      }}
                      disabled={previouscallingBackend}
                    >
                      {previouscallingBackend ? (
                        <CircularProgress
                          size={25}
                          sx={{ color: "#22345B", margin: "0 5px" }}
                        />
                      ) : (
                        "Previous"
                      )}
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#22345B",
                        textTransform: "capitalize",
                        fontFamily: "serif",
                        borderRadius: "8px",
                        ":hover": { backgroundColor: "#22345B" },
                      }}
                      type="submit"
                      disabled={
                        callingBackend ||
                        arr?.some(
                          (item) =>
                            item?.type?.name === "" ||
                            item?.type?.name === null ||
                            item?.type?.name === undefined
                        )
                      }
                    >
                      {callingBackend ? (
                        <CircularProgress
                          size={25}
                          sx={{ color: "#22345B", margin: "0 5px" }}
                        />
                      ) : (
                        "Next"
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
