import React, { useState, useEffect, useRef } from "react";
import customAxios from "../../libs/axios";
import axios from "axios";
import { Formik, Form } from "formik";
import TextField from "@mui/material/TextField";
import InputFeild from "../../components/common/NewTextFeild";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import "../Assesment/assesment.css";
import { Button } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import HydrantFlowChart from '../Chart/HydrantFlowChart';
import { useScreenshot } from "use-react-screenshot";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import storage from "../../firebase/firebase";
import { useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const labels = ["Reliable", "Unreliable"];

export default function HydrantReliability({
  getPage,
  callingBackend,
  setCallingBackend,
  handleNextPage,
}) {
  const { state } = useLocation();
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });
  const [arr, setArr] = useState([]);
  // eslint-disable-next-line
  const [type, setType] = useState("");
  const [comment, setComment] = useState("");
  const [text, setText] = useState(true);
  
  const toolbarOptions = [
    ["bold", "italic", "underline"],
    [{ header: 1 }, { header: 2 }],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }],
  ];
   const module = {
     toolbar: toolbarOptions,
   };
  const [nextpage, setNextpage] = useState(false);
  const changeFormData = (value, name, id) => {
    setType(value);
    const objIndex = arr.findIndex((obj) => obj.id === id);
    arr[objIndex][name] = value;

    setArr([...arr]);
  };

  const ref2 = useRef(null);
  // eslint-disable-next-line
  const [image2, takeScreenshot2] = useScreenshot({
    type: "image2/jpg",
    quality: 1.0,
  });
  const downloadImage2 = () => {
    setCallingBackend(true);
    takeScreenshot2(ref2.current).then((image) => {
      console.log("image", image);
      handleImageAsFile2(image); // Pass the image URL to the function
    });
  };

  const handleImageAsFile2 = async (image) => {
    console.log("image", image);
    try {
      // Convert image URL to Blob
      const response = await axios.get(image, { responseType: "blob" });
      const blob = response.data;

      // Create a unique filename
      const fileName = `image_${new Date().getTime()}.jpg`;

      // Create a reference to the file in Firebase Storage
      const storageRef = ref(storage, `/files/${fileName}`);

      // Upload the Blob
      const uploadTask = uploadBytesResumable(storageRef, blob);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          console.log("percent", percent); // Optional: You can use this to show upload progress
        },
        (err) => console.log("err>>>>>>>>>>>", err),
        () => {
          // Get download URL
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            submit(url);
          });
        }
      );
    } catch (error) {
      console.error(error);
    }
  };
  const submit = async (imageUrl) => {
    try {
      setCallingBackend(true);
      await customAxios.put(`/assesment/hydrant_reliability/${state.id}`, {
        hydrant: arr,
        hydrantimage: imageUrl,
        comment: comment,
      });
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Water supply created successfully!",
      });
      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "Water supply created successfully!",
        });
        if (nextpage) {
          handleNextPage();
        } else {
          getPage();
        }
      }, 5000);
    } catch (error) {
      if (error.response.data === "Water supply already exists") {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Water supply already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Water supply already exists!",
            }),
          5000
        );
      } else if (error.response.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Unauthorized Access!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Unauthorized Access!",
            }),
          5000
        );
      } else if (error.response.status === 500) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Server Error!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Server Error!",
            }),
          5000
        );
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Water supply already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Water supply already exists!",
            }),
          5000
        );
      }
    } finally {
      setCallingBackend(false);
    }
  };
  const get = async () => {
    try {
      const { data } = await customAxios.get(`/assesment/${state.id}`);
      const item = [...data.hydrant_system.hydrant].map((item, index) => {
        if (data.hydrant_system_reliability.hydrant.length) {
          return {
            id: item?.id,
            hydrant_name:
              data?.hydrant_system_reliability?.hydrant[index]?.hydrant_name ||
              item?.area_protected,
            reliability:
              data?.hydrant_system_reliability?.hydrant[index]?.reliability ||
              item?.reliability,
          };
        } else {
          return {
            id: item.id,
            hydrant_name: item.area_protected,
            reliability: "",
          };
        }
      });
      setArr([...item]);
      setComment(data.hydrant_system_reliability.comment);
      setNextpage(data?.assessment?.water_supply_exclude);
    } catch (error) {}
  };
  // const handleClick = () => {
  //   handleFormNumber();
  //   getPage();
  // };
  useEffect(() => {
    get();
    // eslint-disable-next-line
  }, []);
  return (
    <div>
      <Formik enableReinitialize>
        {({ dirty, isValid }) => {
          return (
            <Form>
              <Grid
                container
                xs={12}
                sx={{
                  padding: "1rem",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                }}
              >
                <Grid item xs={12}>
                  <p className="formName">Hydrant System Reliability</p>
                </Grid>
                <Grid container item xs={12}>
                  <FormControlLabel
                    value="start"
                    control={
                      <Switch
                        checked={text}
                        onChange={(e) => {
                          console.log("sfdsgsadf", e.target.checked);
                          setText(e.target.checked);
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="Display labels"
                    labelPlacement="start"
                  />
                </Grid>
                <Grid item xs={12}>
                  <div ref={ref2}>
                    <HydrantFlowChart text={text} />
                  </div>
                </Grid>
                <Grid container spacing={1} xs={12}>
                  <Grid container item xs={12} spacing={1}>
                    <Grid container item xs={3} spacing={1}>
                      <Grid item xs={12}>
                        <p className="inputTextH">Hydrant System</p>{" "}
                      </Grid>
                    </Grid>
                    <Grid container item xs={9} spacing={1}>
                      <Grid item xs={12}>
                        <p className="inputTextH">Reliability</p>{" "}
                      </Grid>
                    </Grid>
                    {arr.map((item, i) => {
                      return (
                        <>
                          <Grid container item xs={3} spacing={1}>
                            <Grid item xs={12}>
                              <p className="inputTextP">{item.hydrant_name}</p>
                            </Grid>
                          </Grid>
                          <Grid container item xs={9} spacing={1}>
                            <Grid item xs={12}>
                              <Autocomplete
                                hiddenLabel
                                id="reliability"
                                name="reliability"
                                size="small"
                                value={item.reliability}
                                options={labels}
                                getOptionLabel={(option) => option}
                                onChange={(event, newValue) => {
                                  changeFormData(
                                    newValue,
                                    "reliability",
                                    item.id
                                  );
                                }}
                                renderInput={(params) => (
                                  <TextField size="small" {...params} />
                                )}
                              />
                            </Grid>
                          </Grid>
                        </>
                      );
                    })}
                    <Grid item xs={12} sx={{ marginTop: "1.5rem" }}>
                      <p className="inputTextP">Comment</p>{" "}
                    </Grid>
                    <Grid item xs={12} sx={{ marginBottom: "50px" }}>
                      <ReactQuill
                        modules={module}
                        theme="snow"
                        value={comment}
                        style={{
                          marginBottom: "25px",
                          height: "150px",
                        }}
                        onChange={setComment}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    sx={{
                      alignContent: "center",
                      justifyContent: "flex-end",
                      marginTop: "5rem",
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#22345B",
                        textTransform: "capitalize",
                        fontFamily: "serif",
                        borderRadius: "8px",
                        ":hover": { backgroundColor: "#22345B" },
                      }}
                      onClick={() => downloadImage2()}
                      disabled={
                        !arr.every(
                          (item) =>
                            !Object.entries(item).some(
                              ([key, value]) =>
                                value === "" ||
                                value === null ||
                                value === undefined
                            )
                        ) || callingBackend
                      }
                    >
                      {callingBackend ? (
                        <CircularProgress
                          size={25}
                          sx={{ color: "#22345B", margin: "0 5px" }}
                        />
                      ) : (
                        "Save"
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
