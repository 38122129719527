import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Grid } from "@mui/material";
import axios from "../../libs/axios";
import { useLocation } from "react-router-dom";

ChartJS?.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function HydrantFlowChart({ text }) {
  const { state } = useLocation();
  const [sprinklersData, setSprinklersData] = useState([]);
  const [hydrantData, setHydrantData] = useState([]);
  const [townMain, setTownMain] = useState([]);
  const [activeDataset, setActiveDataset] = useState(null);

  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          title: () => "",
          label: (context) =>
            `Name: ${context?.raw?.name}, Value: (x:${context?.parsed?.x}, y:${context?.parsed?.y})`,
        },
      },
      legend: {
        position: "top",
        align: "start",
        justifyContent: "center",
        labels: {
          padding: 20,
          usePointStyle: true,
          pointStyle: "circle",
          boxWidth: 10,
          textAlign: "start",
          font: {
            size: 12, // Adjust text size
          },
        },
        onClick: (e, legendItem) => {
          const datasetLabel = legendItem?.text;
          setActiveDataset((prevDataset) =>
            prevDataset === datasetLabel ? null : datasetLabel
          );
        },
      },
      datalabels: {
        display: true,
        align: "top",
        formatter: (value, context) => value?.name,
        color: "black",
      },
    },
    scales: {
      x: {
        type: "linear",
        position: "bottom",
        title: {
          display: true,
          text: "Demand Flow (L/min)",
        },
      },
      y: {
        title: {
          display: true,
          text: "Pressure (kPa)",
        },
      },
    },
  };

  const getDataSets = () => [
    {
      label: "Hydrants",
      data: hydrantData?.map((item) => ({
        x: item?.hydrant_flow,
        y: item?.hydrant_test_location,
        name: "",
      })),
      fill: false,
      borderColor: "#A5862A",
      backgroundColor: "#A5862A",
      tension: 0.4,
      datalabels: {
        display: text,
        align: "right",
        formatter: (value) => value?.name,
      },
      hidden: activeDataset !== null && activeDataset !== "Hydrants",
    },
    {
      label: "Town Main",
      data: townMain?.map((item) => ({
        x: item?.sprinkler_flow,
        y: item?.discharge,
        name: "",
      })),
      fill: false,
      datalabels: {
        display: false,
      },
      // datalabels: {
      //   display: text,
      //   align: "right",
      //   formatter: (value, context) => {
      //     const xValue =
      //       context?.chart?.data?.datasets[context?.datasetIndex]?.data[
      //         context?.dataIndex
      //       ]?.x;
      //     const yValue =
      //       context?.chart?.data?.datasets[context?.datasetIndex]?.data[
      //         context?.dataIndex
      //       ]?.y;

      //     return `x: ${xValue}, y: ${yValue}`;
      //   },
      // },
      borderColor: "#243859",
      backgroundColor: "#243859",
      tension: 0.4,
      hidden: activeDataset !== null && activeDataset !== "Town Main",
    },
    {
      label: sprinklersData[0]?.area_protected
        ? sprinklersData[0]?.area_protected
        : "",
      data: [
        {
          x: sprinklersData[0]?.demand_flow,
          y: sprinklersData[0]?.demand_pressure,
        },
      ],
      fill: false,
      borderColor: sprinklersData[0]?.area_protected
        ? ["#FCADFF"]
        : ["#ffffff"],
      backgroundColor: sprinklersData[0]?.area_protected
        ? ["#FCADFF"]
        : ["#ffffff"],
      tension: 0.4,
      datalabels: {
        display: text,
        align: "right",
        formatter: (value) => value?.name,
      },
      hidden:
        activeDataset !== null &&
        activeDataset !== sprinklersData[0]?.area_protected,
      datalabels: {
        display: text,
        align: "bottom",
        formatter: (value, context) => {
          const xValue =
            context?.chart?.data?.datasets[context?.datasetIndex]?.data[
              context?.dataIndex
            ]?.x;
          const yValue =
            context?.chart?.data?.datasets[context?.datasetIndex]?.data[
              context?.dataIndex
            ]?.y;

          return `${xValue}L/min @ ${yValue}kPa`;
        },
      },
    },
    {
      label: sprinklersData[1]?.area_protected
        ? sprinklersData[1]?.area_protected
        : "",
      data: [
        {
          x: sprinklersData[1]?.demand_flow,
          y: sprinklersData[1]?.demand_pressure,
        },
      ],
      fill: false,
      borderColor: sprinklersData[1]?.area_protected
        ? ["#D176D6"]
        : ["#ffffff"],
      backgroundColor: sprinklersData[1]?.area_protected
        ? ["#D176D6"]
        : ["#ffffff"],
      tension: 0.4,
      datalabels: {
        display: text,
        align: "right",
        formatter: (value) => value?.name,
      },
      hidden:
        activeDataset !== null &&
        activeDataset !== sprinklersData[1]?.area_protected,
      datalabels: {
        display: text,
        align: "bottom",
        formatter: (value, context) => {
          const xValue =
            context?.chart?.data?.datasets[context?.datasetIndex]?.data[
              context?.dataIndex
            ]?.x;
          const yValue =
            context?.chart?.data?.datasets[context?.datasetIndex]?.data[
              context?.dataIndex
            ]?.y;

          return `${xValue}L/min @ ${yValue}kPa`;
        },
      },
    },
    {
      label: sprinklersData[2]?.area_protected
        ? sprinklersData[2]?.area_protected
        : "",
      data: [
        {
          x: sprinklersData[2]?.demand_flow,
          y: sprinklersData[2]?.demand_pressure,
        },
      ],
      fill: false,
      borderColor: sprinklersData[2]?.area_protected
        ? ["#BD50C3"]
        : ["#ffffff"],
      backgroundColor: sprinklersData[2]?.area_protected
        ? ["#BD50C3"]
        : ["#ffffff"],
      tension: 0.4,
      datalabels: {
        display: text,
        align: "right",
        formatter: (value) => value?.name,
      },
      hidden:
        activeDataset !== null &&
        activeDataset !== sprinklersData[2]?.area_protected,
      datalabels: {
        display: text,
        align: "bottom",
        formatter: (value, context) => {
          const xValue =
            context?.chart?.data?.datasets[context?.datasetIndex]?.data[
              context?.dataIndex
            ]?.x;
          const yValue =
            context?.chart?.data?.datasets[context?.datasetIndex]?.data[
              context?.dataIndex
            ]?.y;

          return `${xValue}L/min @ ${yValue}kPa`;
        },
      },
    },
    {
      label: sprinklersData[3]?.area_protected
        ? sprinklersData[3]?.area_protected
        : "",
      data: [
        {
          x: sprinklersData[3]?.demand_flow,
          y: sprinklersData[3]?.demand_pressure,
        },
      ],
      fill: false,
      borderColor: sprinklersData[3]?.area_protected
        ? ["#9C2DA6"]
        : ["#ffffff"],
      backgroundColor: sprinklersData[3]?.area_protected
        ? ["#9C2DA6"]
        : ["#ffffff"],
      tension: 0.4,
      datalabels: {
        display: text,
        align: "right",
        formatter: (value) => value?.name,
      },
      hidden:
        activeDataset !== null &&
        activeDataset !== sprinklersData[3]?.area_protected,
      datalabels: {
        display: text,
        align: "bottom",
        formatter: (value, context) => {
          const xValue =
            context?.chart?.data?.datasets[context?.datasetIndex]?.data[
              context?.dataIndex
            ]?.x;
          const yValue =
            context?.chart?.data?.datasets[context?.datasetIndex]?.data[
              context?.dataIndex
            ]?.y;

          return `${xValue}L/min @ ${yValue}kPa`;
        },
      },
    },
    {
      label: sprinklersData[4]?.area_protected
        ? sprinklersData[4]?.area_protected
        : "",
      data: [
        {
          x: sprinklersData[4]?.demand_flow,
          y: sprinklersData[4]?.demand_pressure,
        },
      ],
      fill: false,
      borderColor: sprinklersData[4]?.area_protected
        ? ["#840A90"]
        : ["#ffffff"],
      backgroundColor: sprinklersData[4]?.area_protected
        ? ["#840A90"]
        : ["#ffffff"],
      tension: 0.4,
      datalabels: {
        display: text,
        align: "right",
        formatter: (value) => value?.name,
      },
      hidden:
        activeDataset !== null &&
        activeDataset !== sprinklersData[4]?.area_protected,
      datalabels: {
        display: text,
        align: "bottom",
        formatter: (value, context) => {
          const xValue =
            context?.chart?.data?.datasets[context?.datasetIndex]?.data[
              context?.dataIndex
            ]?.x;
          const yValue =
            context?.chart?.data?.datasets[context?.datasetIndex]?.data[
              context?.dataIndex
            ]?.y;

          return `${xValue}L/min @ ${yValue}kPa`;
        },
      },
    },
    {
      label: sprinklersData[5]?.area_protected
        ? sprinklersData[5]?.area_protected
        : "",
      data: [
        {
          x: sprinklersData[5]?.demand_flow,
          y: sprinklersData[5]?.demand_pressure,
        },
      ],
      fill: false,
      borderColor: sprinklersData[5]?.area_protected
        ? ["#DBD8D4"]
        : ["#ffffff"],
      backgroundColor: sprinklersData[5]?.area_protected
        ? ["#DBD8D4"]
        : ["#ffffff"],
      tension: 0.4,
      datalabels: {
        display: text,
        align: "right",
        formatter: (value) => value?.name,
      },
      hidden:
        activeDataset !== null &&
        activeDataset !== sprinklersData[5]?.area_protected,
      datalabels: {
        display: text,
        align: "bottom",
        formatter: (value, context) => {
          const xValue =
            context?.chart?.data?.datasets[context?.datasetIndex]?.data[
              context?.dataIndex
            ]?.x;
          const yValue =
            context?.chart?.data?.datasets[context?.datasetIndex]?.data[
              context?.dataIndex
            ]?.y;

          return `${xValue}L/min @ ${yValue}kPa`;
        },
      },
    },
    {
      label: sprinklersData[6]?.area_protected
        ? sprinklersData[6]?.area_protected
        : "",
      data: [
        {
          x: sprinklersData[6]?.demand_flow,
          y: sprinklersData[6]?.demand_pressure,
        },
      ],
      fill: false,
      borderColor: sprinklersData[6]?.area_protected
        ? ["#6F6B6A"]
        : ["#ffffff"],
      backgroundColor: sprinklersData[6]?.area_protected
        ? ["#6F6B6A"]
        : ["#ffffff"],
      tension: 0.4,
      datalabels: {
        display: text,
        align: "right",
        formatter: (value) => value?.name,
      },
      hidden:
        activeDataset !== null &&
        activeDataset !== sprinklersData[6]?.area_protected,
      datalabels: {
        display: text,
        align: "bottom",
        formatter: (value, context) => {
          const xValue =
            context?.chart?.data?.datasets[context?.datasetIndex]?.data[
              context?.dataIndex
            ]?.x;
          const yValue =
            context?.chart?.data?.datasets[context?.datasetIndex]?.data[
              context?.dataIndex
            ]?.y;

          return `${xValue}L/min @ ${yValue}kPa`;
        },
      },
    },
    {
      label: sprinklersData[7]?.area_protected
        ? sprinklersData[7]?.area_protected
        : "",
      data: [
        {
          x: sprinklersData[7]?.demand_flow,
          y: sprinklersData[7]?.demand_pressure,
        },
      ],
      fill: false,
      borderColor: sprinklersData[7]?.area_protected
        ? ["#8D8787"]
        : ["#ffffff"],
      backgroundColor: sprinklersData[7]?.area_protected
        ? ["#8D8787"]
        : ["#ffffff"],
      tension: 0.4,
      datalabels: {
        display: text,
        align: "right",
        formatter: (value) => value?.name,
      },
      hidden:
        activeDataset !== null &&
        activeDataset !== sprinklersData[7]?.area_protected,
      datalabels: {
        display: text,
        align: "bottom",
        formatter: (value, context) => {
          const xValue =
            context?.chart?.data?.datasets[context?.datasetIndex]?.data[
              context?.dataIndex
            ]?.x;
          const yValue =
            context?.chart?.data?.datasets[context?.datasetIndex]?.data[
              context?.dataIndex
            ]?.y;

          return `${xValue}L/min @ ${yValue}kPa`;
        },
      },
    },
    {
      label: sprinklersData[8]?.area_protected
        ? sprinklersData[8]?.area_protected
        : "",
      data: [
        {
          x: sprinklersData[8]?.demand_flow,
          y: sprinklersData[8]?.demand_pressure,
        },
      ],
      fill: false,
      borderColor: sprinklersData[8]?.area_protected
        ? ["#ACA6A6"]
        : ["#ffffff"],
      backgroundColor: sprinklersData[8]?.area_protected
        ? ["#ACA6A6"]
        : ["#ffffff"],
      tension: 0.4,
      datalabels: {
        display: text,
        align: "right",
        formatter: (value) => value?.name,
      },
      hidden:
        activeDataset !== null &&
        activeDataset !== sprinklersData[8]?.area_protected,
      datalabels: {
        display: text,
        align: "bottom",
        formatter: (value, context) => {
          const xValue =
            context?.chart?.data?.datasets[context?.datasetIndex]?.data[
              context?.dataIndex
            ]?.x;
          const yValue =
            context?.chart?.data?.datasets[context?.datasetIndex]?.data[
              context?.dataIndex
            ]?.y;

          return `${xValue}L/min @ ${yValue}kPa`;
        },
      },
    },
    {
      label: sprinklersData[9]?.area_protected
        ? sprinklersData[9]?.area_protected
        : "",
      data: [
        {
          x: sprinklersData[9]?.demand_flow,
          y: sprinklersData[9]?.demand_pressure,
        },
      ],
      fill: false,
      borderColor: sprinklersData[9]?.area_protected
        ? ["#CCC8C7"]
        : ["#ffffff"],
      backgroundColor: sprinklersData[9]?.area_protected
        ? ["#CCC8C7"]
        : ["#ffffff"],
      tension: 0.4,
      datalabels: {
        display: text,
        align: "right",
        formatter: (value) => value?.name,
      },
      hidden:
        activeDataset !== null &&
        activeDataset !== sprinklersData[9]?.area_protected,
      datalabels: {
        display: text,
        align: "bottom",
        formatter: (value, context) => {
          const xValue =
            context?.chart?.data?.datasets[context?.datasetIndex]?.data[
              context?.dataIndex
            ]?.x;
          const yValue =
            context?.chart?.data?.datasets[context?.datasetIndex]?.data[
              context?.dataIndex
            ]?.y;

          return `${xValue}L/min @ ${yValue}kPa`;
        },
      },
    },
    {
      label: sprinklersData[10]?.area_protected
        ? sprinklersData[10]?.area_protected
        : "",
      data: [
        {
          x: sprinklersData[10]?.demand_flow,
          y: sprinklersData[10]?.demand_pressure,
        },
      ],
      fill: false,
      borderColor: sprinklersData[10]?.area_protected
        ? ["#F6E593"]
        : ["#ffffff"],
      backgroundColor: sprinklersData[10]?.area_protected
        ? ["#F6E593"]
        : ["#ffffff"],
      tension: 0.4,
      datalabels: {
        display: text,
        align: "right",
        formatter: (value) => value?.name,
      },
      hidden:
        activeDataset !== null &&
        activeDataset !== sprinklersData[10]?.area_protected,
      datalabels: {
        display: text,
        align: "bottom",
        formatter: (value, context) => {
          const xValue =
            context?.chart?.data?.datasets[context?.datasetIndex]?.data[
              context?.dataIndex
            ]?.x;
          const yValue =
            context?.chart?.data?.datasets[context?.datasetIndex]?.data[
              context?.dataIndex
            ]?.y;

          return `${xValue}L/min @ ${yValue}kPa`;
        },
      },
    },
    {
      label: sprinklersData[11]?.area_protected
        ? sprinklersData[11]?.area_protected
        : "",
      data: [
        {
          x: sprinklersData[11]?.demand_flow,
          y: sprinklersData[11]?.demand_pressure,
        },
      ],
      fill: false,
      borderColor: sprinklersData[11]?.area_protected
        ? ["#DFCB6C"]
        : ["#ffffff"],
      backgroundColor: sprinklersData[11]?.area_protected
        ? ["#DFCB6C"]
        : ["#ffffff"],
      tension: 0.4,
      datalabels: {
        display: text,
        align: "right",
        formatter: (value) => value?.name,
      },
      hidden:
        activeDataset !== null &&
        activeDataset !== sprinklersData[11]?.area_protected,
      datalabels: {
        display: text,
        align: "bottom",
        formatter: (value, context) => {
          const xValue =
            context?.chart?.data?.datasets[context?.datasetIndex]?.data[
              context?.dataIndex
            ]?.x;
          const yValue =
            context?.chart?.data?.datasets[context?.datasetIndex]?.data[
              context?.dataIndex
            ]?.y;

          return `${xValue}L/min @ ${yValue}kPa`;
        },
      },
    },
    {
      label: sprinklersData[12]?.area_protected
        ? sprinklersData[12]?.area_protected
        : "",
      data: [
        {
          x: sprinklersData[12]?.demand_flow,
          y: sprinklersData[12]?.demand_pressure,
        },
      ],
      fill: false,
      borderColor: sprinklersData[12]?.area_protected
        ? ["#BFAA41"]
        : ["#ffffff"],
      backgroundColor: sprinklersData[12]?.area_protected
        ? ["#BFAA41"]
        : ["#ffffff"],
      tension: 0.4,
      datalabels: {
        display: text,
        align: "right",
        formatter: (value) => value?.name,
      },
      hidden:
        activeDataset !== null &&
        activeDataset !== sprinklersData[12]?.area_protected,
      datalabels: {
        display: text,
        align: "bottom",
        formatter: (value, context) => {
          const xValue =
            context?.chart?.data?.datasets[context?.datasetIndex]?.data[
              context?.dataIndex
            ]?.x;
          const yValue =
            context?.chart?.data?.datasets[context?.datasetIndex]?.data[
              context?.dataIndex
            ]?.y;

          return `${xValue}L/min @ ${yValue}kPa`;
        },
      },
    },
    {
      label: sprinklersData[13]?.area_protected
        ? sprinklersData[13]?.area_protected
        : "",
      data: [
        {
          x: sprinklersData[13]?.demand_flow,
          y: sprinklersData[13]?.demand_pressure,
        },
      ],
      fill: false,
      borderColor: sprinklersData[13]?.area_protected
        ? ["#746402"]
        : ["#ffffff"],
      backgroundColor: sprinklersData[13]?.area_protected
        ? ["#746402"]
        : ["#ffffff"],
      tension: 0.4,
      datalabels: {
        display: text,
        align: "right",
        formatter: (value) => value?.name,
      },
      hidden:
        activeDataset !== null &&
        activeDataset !== sprinklersData[13]?.area_protected,
      datalabels: {
        display: text,
        align: "bottom",
        formatter: (value, context) => {
          const xValue =
            context?.chart?.data?.datasets[context?.datasetIndex]?.data[
              context?.dataIndex
            ]?.x;
          const yValue =
            context?.chart?.data?.datasets[context?.datasetIndex]?.data[
              context?.dataIndex
            ]?.y;

          return `${xValue}L/min @ ${yValue}kPa`;
        },
      },
    },
    {
      label: sprinklersData[14]?.area_protected
        ? sprinklersData[14]?.area_protected
        : "",
      data: [
        {
          x: sprinklersData[14]?.demand_flow,
          y: sprinklersData[14]?.demand_pressure,
        },
      ],
      fill: false,
      borderColor: sprinklersData[14]?.area_protected
        ? ["#9E8912"]
        : ["#ffffff"],
      backgroundColor: sprinklersData[14]?.area_protected
        ? ["#9E8912"]
        : ["#ffffff"],
      tension: 0.4,
      datalabels: {
        display: text,
        align: "right",
        formatter: (value) => value?.name,
      },
      hidden:
        activeDataset !== null &&
        activeDataset !== sprinklersData[14]?.area_protected,
      datalabels: {
        display: text,
        align: "bottom",
        formatter: (value, context) => {
          const xValue =
            context?.chart?.data?.datasets[context?.datasetIndex]?.data[
              context?.dataIndex
            ]?.x;
          const yValue =
            context?.chart?.data?.datasets[context?.datasetIndex]?.data[
              context?.dataIndex
            ]?.y;

          return `${xValue}L/min @ ${yValue}kPa`;
        },
      },
    },
  ];

  const data = {
    datasets: getDataSets(),
  };

  const get = async () => {
    try {
      const { data } = await axios?.get(`/assesment/${state?.id}`);
      if (!data?.town_main?.town_mains_check) {
        console?.log(
          "data?.town_main?.town_mains",
          data?.town_main?.town_mains
        );
        setTownMain(data?.town_main?.town_mains);
      } else {
        setTownMain([]);
      }
      if (!data?.town_main?.hydrant_check) {
        console?.log(
          "data?.town_main?.hydrant_check",
          data?.town_main?.hydrant
        );
        setHydrantData(data?.town_main?.hydrant);
      } else {
        setHydrantData([]);
      }
      setSprinklersData(data?.hydrant_system?.hydrant);
    } catch (error) {
      console?.error(error);
    }
  };

  useEffect(() => {
    get();
  }, []);

  return (
    <div>
      <Grid
        container
        xs={12}
        sx={{
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "flex-start",
        }}
      >
        <Grid container item xs={12}>
          <Line options={options} data={data} />
        </Grid>
      </Grid>
    </div>
  );
}
