import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  company_name: Yup.string()
    .required("Company name is required!")
    .min(3, "Minimum Word count is 3!")
    .max(50, "Maximum Word count is 50!"),
  company_abn: Yup.string().required("Company ABN is required!"),
  company_contact_first_name: Yup.string()
    .required("First name is required!")
    .min(3, "Minimum Word count is 3!")
    .max(50, "Maximum Word count is 50!"),
  company_contact_last_name: Yup.string()
    .required("Last name is required!")
    .min(3, "Minimum Word count is 3!")
    .max(50, "Maximum Word count is 50!"),
  company_email: Yup.string()
    .required("Email is required!")
    .trim()
    .matches(
      // eslint-disable-next-line
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter a valid email"
    ),
  company_contact_email: Yup.string()
    .required("Email is required!")
    .trim()
    .matches(
      // eslint-disable-next-line
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter a valid email"
    )
    .max(50, "Maximum character count is 50!"),

  company_contact_number: Yup.string()
    .required("Company contact number is required!"),
  company_contact_job: Yup.string()
    .required("Contact job title is required!")
    .min(3, "Minimum Word count is 3!")
    .max(50, "Maximum Word count is 50!"),
  unit_no: Yup.string()
    .required("Unit number is required!")
    .min(1, "Minimum Word count is 1!")
    .max(50, "Maximum Word count is 50!"),
  zip: Yup.string()
    .required("Post code is required!")
    .min(1, "Minimum Word count is 1!")
    .max(50, "Maximum Word count is 50!"),
  street_name: Yup.string()
    .required("Street name is required!")
    .max(50, "Maximum Word count is 50!"),
  suburb: Yup.string()
    .required("Suburb name is required!")
    .max(50, "Maximum Word count is 50!"),
  country: Yup.string()
    .required("Country is required!")
    .max(50, "Maximum Word count is 50!"),
  assigned_engineer: Yup.string()
    .required("Assigned engineer is required!")
    .min(2, "Minimum Word count is 2!")
    .max(50, "Maximum Word count is 50!"),
  broker_contact_name: Yup.string()
    .required("Broker contact name is required!")
    .min(2, "Minimum Word count is 2!")
    .max(50, "Maximum Word count is 50!"),
  broker_contact_job: Yup.string()
    .required("Broker contact job is required!")
    .min(2, "Minimum Word count is 2!")
    .max(50, "Maximum Word count is 50!"),
  broker_contact_company: Yup.string()
    .required("Broker contact company is required!")
    .min(2, "Minimum Word count is 2!")
    .max(50, "Maximum Word count is 50!"),
  site_title: Yup.string()
    .required("Site title is required!")
    .min(1, "Minimum Word count is 1!")
    .max(50, "Maximum Word count is 10!"),
});
