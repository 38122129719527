import React, { useState, useEffect } from "react";
import axios from "../../libs/axios";
import { Formik, Form } from "formik";
import TextField from "@mui/material/TextField";
import InputFeild from "../../components/common/NewTextFeild";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import "../Assesment/assesment.css";
import { Button } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const labels = ["Towns Main", "Towns Main and Pump", "Pump and Tank"];

export default function WaterSupply({
  formData,
  handlePreviousPage,
  previouscallingBackend,
  setPreviousCallingBackend,
  handleNextPage,
  callingBackend,
  setCallingBackend,
}) {
  const toolbarOptions = [
    ["bold", "italic", "underline"],
    [{ header: 1 }, { header: 2 }],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }],
  ];
  const module = {
    toolbar: toolbarOptions,
  };
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });
  const { state } = useLocation();
  const [checkWS, setCheckWS] = useState(
    formData?.water_supply?.water_supply_skip ? true : false
  );

  const [arr, setArr] = useState([]);
  const [comment, setComment] = useState(
    formData?.water_supply?.comment ? formData?.water_supply?.comment : ""
  );

  const changeFormData = (value, name, id) => {
    const objIndex = arr.findIndex((obj) => obj.id === id);
    arr[objIndex][name] = value;

    setArr([...arr]);
  };
  const handleWaterSupply = (event) => {
    setCheckWS(event.target.checked);
  };

  const submit = async (e) => {
    const updatedSprinklerSystems = arr.map((system) => {
      if (checkWS) {
        return {
          ...system,
          demand_flow: "0",
          demand_pressure: "0",
        };
      } else {
        return {
          ...system,
          demand_flow:
            system.demand_flow === undefined ? "0" : system.demand_flow,
          demand_pressure:
            system.demand_pressure === undefined ? "0" : system.demand_pressure,
        };
      }
    });
    console.log("updatedSprinklerSystems", updatedSprinklerSystems);
    const sprinklerskip = [...formData.sprinklers.protection].map(
      (item, index) => {
        if (formData.sprinkler_system_reliability.sprinkler.length) {
          return {
            id: item?.id,
            sprinkler_system:
              formData?.sprinkler_system_reliability?.sprinkler[index]
                ?.sprinkler_system || item?.area_protection,
            reliability:
              formData?.sprinkler_system_reliability?.sprinkler[index]
                ?.reliability || item?.reliability,
          };
        } else {
          return {
            id: item.id,
            sprinkler_system: item.area_protection,
            reliability: "",
          };
        }
      }
    );
    const updatedSprinkler = sprinklerskip.map((system) => {
      return {
        ...system,
        reliability: "Unknown",
      };
    });
    try {
      setCallingBackend(true);
      await axios.put(`/assesment/water_supply/${state.id}`, {
        place: updatedSprinklerSystems,
        water_supply_skip: checkWS,
        comment: comment,
      });
      if (formData?.assessment?.water_supply_exclude && checkWS) {
        await axios.put(`/assesment/sprinkler_reliability/${state.id}`, {
          sprinkler: updatedSprinkler,
          comment:
            "A Risk Improvement for the site's water supply has been recommended to assess the reliability of its automatic sprinkler system.",
        });
      }
      if (formData?.assessment?.water_supply_exclude && !checkWS) {
        await axios.put(`/assesment/sprinkler_reliability/${state.id}`, {
          sprinkler: updatedSprinkler,
          comment:
            "A Risk Improvement to provide automatic sprinkler system water supplies for engineering analysis has been recommended for this site.",
        });
      }
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Water supply created successfully!",
      });
      setTimeout(
        () =>
          setAlert({
            showAlert: false,
            severity: "success",
            message: "Water supply created successfully!",
          }),
        handleNextPage(),
        5000
      );
    } catch (error) {
      if (error.response.data === "Water supply already exists") {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Water supply already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Water supply already exists!",
            }),
          5000
        );
      } else if (error.response.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Unauthorized Access!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Unauthorized Access!",
            }),
          5000
        );
      } else if (error.response.status === 500) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Server Error!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Server Error!",
            }),
          5000
        );
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Water supply already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Water supply already exists!",
            }),
          5000
        );
      }
    }
  };
  const get = async () => {
    try {
      const { data } = await axios.get(`/assesment/${state.id}`);
      const item = [...data.sprinklers.protection].map((item, index) => {
        if (data.water_supply.place.length) {
          return {
            id: item?.id,
            sprinkler_system:
              data?.water_supply?.place[index]?.sprinkler_system ||
              item?.area_protection,
            demand_flow:
              data?.water_supply?.place[index]?.demand_flow ||
              item?.demand_flow,
            demand_pressure:
              data?.water_supply?.place[index]?.demand_pressure ||
              item?.demand_pressure,
            water_supply_type:
              data?.water_supply?.place[index]?.water_supply_type ||
              item?.water_supply_type,
            capacity:
              data?.water_supply?.place[index]?.capacity || item?.capacity,
          };
        } else {
          return {
            id: item.id,
            sprinkler_system: item.area_protection,
            demand_flow: "",
            demand_pressure: "",
            water_supply_type: "",
            capacity: "",
          };
        }
      });

      setArr([...item]);
    } catch (error) {}
  };
  const handleClick = () => {
    setPreviousCallingBackend(true);
    handlePreviousPage();
  };
  useEffect(() => {
    get();
    // eslint-disable-next-line
  }, []);
  return (
    <div>
      <Formik enableReinitialize>
        {({ dirty, isValid }) => {
          return (
            <Form>
              <Grid
                container
                xs={12}
                sx={{
                  padding: "1rem",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="formName">Automatic Sprinkler System Demands</p>
                <Grid container xs={10} spacing={1}>
                  <Grid container item xs={12} spacing={1}>
                    <Grid item xs={12}>
                      <FormControlLabel
                        value="start"
                        control={
                          <Switch
                            checked={checkWS}
                            onChange={(e) => {
                              handleWaterSupply(e);
                            }}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="Water Supply data disable"
                        labelPlacement="start"
                      />
                    </Grid>
                    <Grid container item xs={3} spacing={1}>
                      <Grid item xs={12}>
                        <p className="inputTextH">Sprinkler System</p>{" "}
                      </Grid>
                    </Grid>
                    <Grid container item xs={3} spacing={1}>
                      <Grid item xs={12}>
                        <p className="inputTextH">Water Supply Type</p>{" "}
                      </Grid>
                    </Grid>
                    <Grid container item xs={2} spacing={1}>
                      <Grid item xs={12}>
                        <p className="inputTextH">Capacity</p>{" "}
                      </Grid>
                    </Grid>
                    <Grid container item xs={2} spacing={1}>
                      <Grid item xs={12}>
                        <p className="inputTextH">Demand Flow (L/min)</p>{" "}
                      </Grid>
                    </Grid>
                    <Grid container item xs={2} spacing={1}>
                      <Grid item xs={12}>
                        <p className="inputTextH">Demand Pressure (kPa)</p>{" "}
                      </Grid>
                    </Grid>
                    {arr.map((item, i) => {
                      return (
                        <>
                          <Grid container item xs={3} spacing={1}>
                            <Grid item xs={12}>
                              <p className="inputTextP">
                                {item.sprinkler_system}
                              </p>
                            </Grid>
                          </Grid>

                          <Grid container item xs={3} spacing={1}>
                            <Grid item xs={12}>
                              <Autocomplete
                                hiddenLabel
                                id="water_supply_type"
                                name="water_supply_type"
                                size="small"
                                value={item.water_supply_type}
                                options={labels}
                                getOptionLabel={(option) => option}
                                onChange={(event, newValue) => {
                                  changeFormData(
                                    newValue,
                                    "water_supply_type",
                                    item.id
                                  );
                                }}
                                renderInput={(params) => (
                                  <TextField size="small" {...params} />
                                )}
                              />
                            </Grid>
                          </Grid>
                          <Grid container item xs={2} spacing={1}>
                            <Grid item xs={12}>
                              <InputFeild
                                name="capacity"
                                value={item.capacity}
                                onChange={(e) =>
                                  changeFormData(
                                    e.target.value,
                                    "capacity",
                                    item.id
                                  )
                                }
                                component={TextField}
                                variant
                                fullWidth
                                bgColor="#FFFFFF"
                                margin="dense"
                              />{" "}
                            </Grid>
                          </Grid>
                          <Grid container item xs={2} spacing={1}>
                            <Grid item xs={12}>
                              <InputFeild
                                name="demand_flow"
                                value={checkWS ? "0" : item.demand_flow}
                                onChange={(e) =>
                                  changeFormData(
                                    e.target.value,
                                    "demand_flow",
                                    item.id
                                  )
                                }
                                disabled={checkWS}
                                component={TextField}
                                variant
                                fullWidth
                                bgColor="#FFFFFF"
                                margin="dense"
                                type="number"
                              />{" "}
                            </Grid>
                          </Grid>
                          <Grid container item xs={2} spacing={1}>
                            <Grid item xs={12}>
                              <InputFeild
                                name="demand_pressure"
                                value={checkWS ? "0" : item.demand_pressure}
                                onChange={(e) =>
                                  changeFormData(
                                    e.target.value,
                                    "demand_pressure",
                                    item.id
                                  )
                                }
                                disabled={checkWS}
                                component={TextField}
                                variant
                                fullWidth
                                bgColor="#FFFFFF"
                                margin="dense"
                                type="number"
                              />{" "}
                            </Grid>
                          </Grid>
                        </>
                      );
                    })}
                    <Grid
                      container
                      item
                      xs={12}
                      spacing={1}
                      sx={{ marginTop: "20px" }}
                    >
                      <p className="inputTextH">
                        Street Main Size and Location Comment
                      </p>{" "}
                    </Grid>
                    <Grid item xs={12} sx={{ marginBottom: "50px" }}>
                      <ReactQuill
                        modules={module}
                        theme="snow"
                        value={comment}
                        style={{ marginBottom: "25px", height: "200px" }}
                        onChange={setComment}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    sx={{
                      alignContent: "center",
                      justifyContent: "space-between",
                      marginTop: "2rem",
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => handleClick()}
                      sx={{
                        backgroundColor: "#22345B",
                        textTransform: "capitalize",
                        fontFamily: "serif",
                        borderRadius: "8px",
                        ":hover": { backgroundColor: "#22345B" },
                      }}
                      disabled={previouscallingBackend}
                    >
                      {previouscallingBackend ? (
                        <CircularProgress
                          size={25}
                          sx={{ color: "#22345B", margin: "0 5px" }}
                        />
                      ) : (
                        "Previous"
                      )}
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#22345B",
                        textTransform: "capitalize",
                        fontFamily: "serif",
                        borderRadius: "8px",
                        ":hover": { backgroundColor: "#22345B" },
                      }}
                      onClick={() => submit()}
                      disabled={
                        !arr.every(
                          (item) =>
                            !Object.entries(item).some(
                              ([key, value]) =>
                                item.water_supply_type === "" ||
                                item.capacity === "" ||
                                item.another_property === "" // Replace with the correct property
                            )
                        ) || callingBackend
                      }
                    >
                      {callingBackend ? (
                        <CircularProgress
                          size={25}
                          sx={{ color: "#22345B", margin: "0 5px" }}
                        />
                      ) : (
                        "Next"
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
