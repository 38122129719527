import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  valuable_commodities: Yup.string().required("Required!"),
  yard_storage: Yup.string().required("Required!"),
  gates_fencing_barriers: Yup.string().required("Required!"),
  lighting: Yup.string().required("Required!"),
  cctv: Yup.string().required("Required!"),
  security_patrols: Yup.string().required("Required!"),
  alarms: Yup.string().required("Required!"),
});
