import React from "react";
import MainScreen from './screens/Assesment/MainScreen';
import { Grid, Typography } from '@mui/material';

function App() {
  return (
    <div>
      <MainScreen />
    </div>
  );
}

export default App;
