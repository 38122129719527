import React, { useState, useEffect } from "react";
import axios from "../../libs/axios";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import "../Assesment/assesment.css";
import { Button } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

const names = [
  {
    name: "Fire Resistive (reinforced concrete, brick, concrete block, brick veneer, stone)",
    element: (
      <p>
        <strong>Fire Resistive</strong>{" "}
        <em>
          (reinforced concrete, brick, concrete block, brick veneer, stone)
        </em>
      </p>
    ),
    value: 30,
  },
  {
    name: "Non Combustible (steel on steel frame)",
    element: (
      <p>
        <strong>Non Combustible</strong> <em>(steel on steel frame)</em>
      </p>
    ),
    value: 24,
  },
  {
    name: "Semi Conbustible with no EPS (fire resistive or non combustible construction with some wood or other inferior construction materials)",
    element: (
      <p>
        <strong>Semi Conbustible with no EPS</strong>{" "}
        <em>
          (fire resistive or non combustible construction with some wood or
          other inferior construction materials)
        </em>
      </p>
    ),
    value: 20,
  },
  {
    name: "Mixed with No EPS (fire resistive with wood and other inferior construction)",
    element: (
      <p>
        <strong>Mixed with No EPS</strong>{" "}
        <em>(fire resistive with wood and other inferior construction)</em>
      </p>
    ),
    value: 15,
  },
  {
    name: "Mixed with EPS (fire resistive with less than 25% EPS)",
    element: (
      <p>
        <strong>Mixed with EPS</strong>{" "}
        <em>(fire resistive with less than 25% EPS)</em>
      </p>
    ),
    value: 10,
    ri: 1,
  },
  {
    name: "Combustible (less than 25% EPS)",
    element: (
      <p>
        <strong>Combustible</strong> <em>(less than 25% EPS)</em>
      </p>
    ),
    value: 6,
    ri: 1,
  },
  {
    name: "Highly Combustible (greater than 25% EPS)",
    element: (
      <p>
        <strong>Highly Combustible</strong> <em>(greater than 25% EPS)</em>
      </p>
    ),
    value: 3,
    ri: 1,
  },
];

const constructionLabels = [
  { name: "Reinforced concrete", value: 30 },
  { name: "Non combustible", value: 29 },
  { name: "Approved panel", value: 27 },
  { name: "Timber frame", value: 24 },
  { name: "EPS / Comb. panel", value: 21 },
];

export default function StarRateOne({
  formData,
  handlePreviousPage,
  previouscallingBackend,
  setPreviousCallingBackend,
  handleNextPage,
  callingBackend,
  setCallingBackend,
}) {
  const { state } = useLocation();

  const [title, setTitle] = useState();
  const [ccategory, setCcategory] = useState();

  const [initialValues, setInitialValues] = useState({
    construction_category: "",
    predominant_construction: "",
  });
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });
  const submit = async () => {
    try {
      setCallingBackend(true);
      await axios.put(`/assesment/star_rate/${state.id}`, {
        construction_category: title || initialValues.construction_category,
        predominant_construction:
          ccategory || initialValues.predominant_construction,
      });
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Star rate page one created successfully!",
      });
      setTimeout(
        () =>
          setAlert({
            showAlert: false,
            severity: "success",
            message: "Star rate page one created successfully!",
          }),
        handleNextPage(),
        5000
      );
    } catch (error) {
      if (error.response.data === "Star rate page one already exists") {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Star rate page one already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Star rate page one already exists!",
            }),
          5000
        );
      } else if (error.response.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Unauthorized Access!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Unauthorized Access!",
            }),
          5000
        );
      } else if (error.response.status === 500) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Server Error!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Server Error!",
            }),
          5000
        );
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Star rate page one already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Star rate page one already exists!",
            }),
          5000
        );
      }
    }
  };

  const handleClick = () => {
    setPreviousCallingBackend(true);
    handlePreviousPage();
  };
  useEffect(() => {
    setInitialValues({
      construction_category: formData?.star_rating_page1?.construction_category,
      predominant_construction:
        formData?.star_rating_page1?.predominant_construction,
    });
    setCcategory(formData?.star_rating_page1?.predominant_construction);
    setTitle(formData?.star_rating_page1?.construction_category);
  }, [formData]);
  return (
    <div>
      <Grid
        container
        xs={12}
        sx={{
          padding: "1rem",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p className="formName">
          PRS AREA - Overall Construction Classification
        </p>
        <Grid container xs={10} spacing={1}>
          <Grid item xs={6}>
            <p className="inputTextP">Overall Construction Category</p>
            <Autocomplete
              hiddenLabel
              id="construction_category"
              name="construction_category"
              size="small"
              value={
                names.find(
                  (option) => option.name === (title ? title.name : "")
                ) || null
              }
              options={names}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                setTitle(newValue);
              }}
              renderOption={(props, option) => (
                <li {...props}>{option.element}</li>
              )}
              renderInput={(params) => <TextField size="small" {...params} />}
            />
          </Grid>
          <Grid item xs={6}>
            <p className="inputTextP">Roof / Predominant construction</p>
            <Autocomplete
              hiddenLabel
              id="predominant_construction"
              name="predominant_construction"
              size="small"
              value={
                ccategory ||
                initialValues?.predominant_construction || {
                  name: "",
                  value: 0,
                }
              }
              options={constructionLabels}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                setCcategory(newValue);
              }}
              renderInput={(params) => <TextField size="small" {...params} />}
            />
          </Grid>
          <Grid
            container
            item
            xs={12}
            sx={{
              alignContent: "center",
              justifyContent: "space-between",
              marginTop: "2rem",
            }}
          >
            <Button
              variant="contained"
              onClick={() => handleClick()}
              sx={{
                backgroundColor: "#22345B",
                textTransform: "capitalize",
                fontFamily: "serif",
                borderRadius: "8px",
                ":hover": { backgroundColor: "#22345B" },
              }}
              disabled={previouscallingBackend}
            >
              {previouscallingBackend ? (
                <CircularProgress
                  size={25}
                  sx={{ color: "#22345B", margin: "0 5px" }}
                />
              ) : (
                "Previous"
              )}
            </Button>
            <Button
              variant="contained"
              onClick={() => submit()}
              sx={{
                backgroundColor: "#22345B",
                textTransform: "capitalize",
                fontFamily: "serif",
                borderRadius: "8px",
                ":hover": { backgroundColor: "#22345B" },
              }}
              disabled={!title || !ccategory || callingBackend}
            >
              {callingBackend ? (
                <CircularProgress
                  size={25}
                  sx={{ color: "#22345B", margin: "0 5px" }}
                />
              ) : (
                "Next"
              )}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
