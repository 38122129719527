import React, { useState, useEffect } from "react";
import axios from "../../libs/axios";
import { Formik, Form } from "formik";
import TextField from "@mui/material/TextField";
// import InputFeild from "../../components/common/NewTextFeild";
import Grid from "@mui/material/Grid";
import "../Assesment/assesment.css";
import { Button, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import InputFeild from "../../components/common/NewTextFeild";
import { useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";


const priorityType = ["Urgent", "Advisory", "Closed"];
const clientResponseType = [
  "Long Term Commitment To Complete",
  "Too Onerous To Complete",
  "Will Be Completed In 90 Days",
];

export default function SpecialHazardRi({
  formData,
  handlePreviousPage,
  handleWlePreviousPage,
  previouscallingBackend,
  setPreviousCallingBackend,
  handleNextPage,
  callingBackend,
  setCallingBackend,
}) {
  const hazard = [
    {
      id: 0,
      name: "Are ignitable liquids in operation (eg. hydraulic oils, heat transfer fluids) handled appropriately (detection, interlocks, shutdowns, alarms etc.)?",
      type: { name: "", value: 1 },
      ri: 10,
      description: "",
    },
    {
      id: 1,
      name: "Are all ignitable liquids stored adequately designed (bunding, drainage, ventilation, etc)?",
      type: { name: "", value: 1 },
      ri: 11,
      description: "",
    },
    {
      id: 2,
      name: "Is adequate separation provided between specialised electrical equipment (forklift charging stations, heaters etc.) and combustibles?",
      type: { name: "", value: 1 },
      ri: 12,
      description: "",
    },
    {
      id: 3,
      name: "Are all Liquid Insulated Transformers adequaltely separated from each other and buildings or appropriate fire-rated barriers provided?",
      type: { name: "", value: 1 },
      ri: 23,
      description: "",
    },
    {
      id: 4,
      name: "Is dissolved gas analysis conducted as part of annual maintenance on all Liquid Insulated Transformers?",
      type: { name: "", value: 1 },
      ri: 13,
      description: "",
    },
    {
      id: 5,
      name: "Are all boilers serviced annually, including testing of each component of a the gas fuel train?",
      type: { name: "", value: 1 },
      ri: 24,
      description: "",
    },
    {
      id: 6,
      name: "Are all idle pallets and ignitable yard storage  stored at least 5m away from buildings or adequately protected?",
      type: { name: "", value: 1 },
      ri: 14,
      description: "",
    },
    {
      id: 7,
      name: "Are all generators tested monthly/ annually serviced/ adequately bunded with interlocks and shutdowns?",
      type: { name: "", value: 1 },
      ri: 15,
      description: "",
    },
    {
      id: 8,
      name: "",
      type: { name: "", value: 1 },
      ri: 0,
      description: "",
    },
    {
      id: 9,
      name: "",
      type: { name: "", value: 1 },
      ri: 0,
      description: "",
    },
    {
      id: 10,
      name: "",
      type: { name: "", value: 1 },
      ri: 0,
      description: "",
    },
    {
      id: 11,
      name: "",
      type: { name: "", value: 1 },
      ri: 0,
      description: "",
    },
  ];

  const toolbarOptions = [
    ["bold", "italic", "underline"],
    [{ header: 1 }, { header: 2 }],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }],
  ];

  const module = {
    toolbar: toolbarOptions,
  };
  const [riheading, setRiheading] = useState(
    formData?.executive_summary?.heading1
      ? formData?.executive_summary?.heading1
      : ""
  );
   const [checkRi, setCheckRi] = useState(
     formData?.fire_detection_protection_priority?.fdp_check ? true : false
   );
  const [addarr, setAddArr] = useState([]);
  const [number, setNumber] = useState(1);
  const addInput = () => {
    setNumber(number + 1);
    setAddArr((s) => {
      return [
        ...s,
        {
          id: number,
          ri: 50 + number,
          title: "",
          details: "",
          theRisk: "",
          priority: "",
          client_response: "",
          client_comment: "",
        },
      ];
    });
  };
 const handleRi = (event) => {
   setCheckRi(event.target.checked);
 };
  const changeArrFormData = (value, name, id) => {
    const objIndex = addarr.findIndex((obj) => obj._id === id);
    addarr[objIndex][name] = value;

    setAddArr([...addarr]);
  };

  const { state } = useLocation();

  const [arr, setArr] = useState(hazard);
  // const [m_parr, setm_pArr] = useState([]);
  // const [n_harr, setn_hArr] = useState([]);
  // const [n_h_rarr, setn_h_rArr] = useState([]);

  const [availableScore, setAvailableScore] = useState(-1);
  const [wleExclude, setWleExclude] = useState(false);

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const submit = async (e) => {
    try {
      setCallingBackend(true);
      await axios.put(`/assesment/special_hazard/${state.id}`, {
        hazard: arr,
        available_score: availableScore,
        ritext: riheading,
        richeck: checkRi,
      });
      console.log("addarr", addarr);
      await axios.put(`/assesment/new-ri/${state.id}`, {
        ri: addarr,
      });
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Star rate page one created successfully!",
      });
      setTimeout(
        () =>
          setAlert({
            showAlert: false,
            severity: "success",
            message: "Star rate page one created successfully!",
          }),
        handleNextPage(),
        5000
      );
    } catch (error) {
      if (error.response.data === "Star rate page one already exists") {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Star rate page one already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Star rate page one already exists!",
            }),
          5000
        );
      } else if (error.response.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Unauthorized Access!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Unauthorized Access!",
            }),
          5000
        );
      } else if (error.response.status === 500) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Server Error!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Server Error!",
            }),
          5000
        );
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Star rate page one already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Star rate page one already exists!",
            }),
          5000
        );
      }
    }
  };
  const handleClick = () => {
    setPreviousCallingBackend(true);
    if (wleExclude) {
      handleWlePreviousPage();
    } else {
      handlePreviousPage();
    }
  };
  const deleteField = (id) => {
    setAddArr((prevArr) => prevArr.filter((item) => item._id !== id));
    console.log('item',id)
    //  setNumber(number - 1);
  };

  useEffect(() => {
    if (formData?.special_hazard?.hazard.length) {
      setArr([...formData?.special_hazard?.hazard]);
      setAvailableScore(formData?.special_hazard?.available_score);
    }

    setWleExclude(formData?.assessment?.wle_exclude);
  }, [formData, arr]);

  useEffect(() => {
    if (formData?.new_ri?.ri.length) {
      setAddArr([...formData?.new_ri?.ri]);
    }
    setNumber(formData?.new_ri?.ri.length);
  }, [formData]);

  return (
    <div>
      <Formik
        initialValues={hazard}
        onSubmit={submit}
        // validationSchema={validationSchema}
        enableReinitialize
      >
        {({ dirty, isValid }) => {
          return (
            <Form>
              <Grid
                container
                xs={12}
                sx={{
                  padding: "1rem",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="formName">Additional Risk Improvement</p>
                <Grid container xs={10} spacing={1}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      value="start"
                      control={
                        <Switch
                          checked={checkRi}
                          onChange={(e) => {
                            handleRi(e);
                          }}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="Risk Improvement description disable"
                      labelPlacement="start"
                    />
                  </Grid>
                  <Grid item xs={12} x={{ marginBottom: "100px" }}>
                    <ReactQuill
                      modules={module}
                      theme="snow"
                      value={
                        riheading
                          ? riheading
                          : `Risk improvements to install automatic sprinkler systems and water supplies throughout the site have been classified as advisory due to a cost of instating such systems.
                          Please refer to the Automatic Sprinklers Section and Other Fire Response Section of this report for further information.`
                      }
                      style={{ marginBottom: "80px", height: "150px" }}
                      onChange={setRiheading}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ marginTop: "2rem" }}>
                    <Typography className="inputTextH">
                      Add Additional Detailed Risk Improvements :
                    </Typography>
                  </Grid>
                  {addarr.map((item, i) => {
                    return (
                      <Grid
                        container
                        item
                        spacing={1}
                        xs={12}
                        style={{
                          justifyContent: "center",
                          alignContent: "center",
                          flexDirection: "row",
                          marginTop: "1rem",
                        }}
                      >
                        <Grid item xs={12}>
                          <p
                            className="inputTextP"
                            style={{ paddingTop: "1rem" }}
                          >
                            Title
                          </p>{" "}
                          <InputFeild
                            name="rioption title"
                            value={item?.title}
                            onChange={(e) =>
                              changeArrFormData(
                                e?.target?.value,
                                "title",
                                item?._id
                              )
                            }
                            component={TextField}
                            variant
                            fullWidth
                            bgColor="#FFFFFF"
                            margin="dense"
                          />
                        </Grid>
                        <Grid item xs={12} sx={{ marginBottom: "2rem" }}>
                          <p
                            className="inputTextP"
                            style={{ paddingTop: "1rem" }}
                          >
                            Details
                          </p>{" "}
                          <ReactQuill
                            modules={module}
                            theme="snow"
                            value={item?.details}
                            style={{ marginBottom: "25px", height: "200px" }}
                            onChange={(e) =>
                              changeArrFormData(e, "details", item?._id)
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sx={{ marginBottom: "2rem" }}>
                          <p
                            className="inputTextP"
                            style={{ paddingTop: "1rem" }}
                          >
                            Risk
                          </p>{" "}
                          <ReactQuill
                            modules={module}
                            theme="snow"
                            value={item?.theRisk}
                            style={{ marginBottom: "25px", height: "200px" }}
                            onChange={(e) =>
                              changeArrFormData(e, "theRisk", item?._id)
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sx={{ marginBottom: "1rem" }}>
                          <p
                            className="inputTextP"
                            style={{ paddingTop: "1rem" }}
                          >
                            {" "}
                            Priority
                          </p>
                          <Autocomplete
                            hiddenLabel
                            id="priority"
                            name="priority"
                            size="small"
                            value={item.priority}
                            options={priorityType}
                            getOptionLabel={(option) => option}
                            onChange={(event, newValue) =>
                              changeArrFormData(newValue, "priority", item?._id)
                            }
                            renderInput={(params) => (
                              <TextField size="small" {...params} />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sx={{ marginBottom: "1rem" }}>
                          <p
                            className="inputTextP"
                            style={{ paddingTop: "1rem" }}
                          >
                            {" "}
                            Client Response
                          </p>
                          <Autocomplete
                            hiddenLabel
                            id="client_response"
                            name="client_response"
                            size="small"
                            value={item.client_response}
                            options={clientResponseType}
                            getOptionLabel={(option) => option}
                            onChange={(event, newValue) =>
                              changeArrFormData(
                                newValue,
                                "client_response",
                                item?._id
                              )
                            }
                            renderInput={(params) => (
                              <TextField size="small" {...params} />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sx={{ marginBottom: "2rem" }}>
                          <p
                            className="inputTextP"
                            style={{ paddingTop: "1rem" }}
                          >
                            {" "}
                            Client Comment
                          </p>
                          <ReactQuill
                            modules={module}
                            theme="snow"
                            value={item?.client_comment}
                            style={{ marginBottom: "25px", height: "200px" }}
                            onChange={(e) =>
                              changeArrFormData(e, "client_comment", item?._id)
                            }
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={{ display: "flex", justifyContent: "flex-end" }}
                        >
                          <Button
                            onClick={() => deleteField(item._id)}
                            variant="outlined"
                            color="error"
                            sx={{
                              marginTop: "1rem",
                              textTransform: "capitalize",
                              marginLeft: "0.5rem",
                            }}
                          >
                            Delete
                          </Button>
                        </Grid>
                      </Grid>
                    );
                  })}
                  <Button
                    onClick={addInput}
                    variant="outlined"
                    sx={{
                      textTransform: "capitalize",
                      marginTop: "1.5rem",
                      marginLeft: "0.5rem",
                    }}
                    disabled={
                      addarr.length >= 5 ||
                      !addarr.every(
                        (item) =>
                          !Object.entries(item).some(
                            ([key, value]) =>
                              item.title === "" ||
                              item.title === undefined ||
                              item.title === null ||
                              item.details === "" ||
                              item.details === undefined ||
                              item.details === null ||
                              item.theRisk === "" ||
                              item.theRisk === undefined ||
                              item.theRisk === null
                          )
                      )
                    }
                  >
                    Add Field
                  </Button>
                  <Grid
                    container
                    item
                    xs={12}
                    sx={{
                      alignContent: "center",
                      justifyContent: "space-between",
                      marginTop: "2rem",
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => handleClick()}
                      sx={{
                        backgroundColor: "#22345B",
                        textTransform: "capitalize",
                        fontFamily: "serif",
                        borderRadius: "8px",
                        ":hover": { backgroundColor: "#22345B" },
                      }}
                      disabled={previouscallingBackend}
                    >
                      {previouscallingBackend ? (
                        <CircularProgress
                          size={25}
                          sx={{ color: "#22345B", margin: "0 5px" }}
                        />
                      ) : (
                        "Previous"
                      )}
                    </Button>
                    <Button
                      variant="contained"
                      type="submit"
                      sx={{
                        backgroundColor: "#22345B",
                        textTransform: "capitalize",
                        fontFamily: "serif",
                        borderRadius: "8px",
                        ":hover": { backgroundColor: "#22345B" },
                      }}
                      disabled={
                        callingBackend ||
                        !addarr.every(
                          (item) =>
                            !Object.entries(item).some(
                              ([key, value]) =>
                                item.title === "" ||
                                item.title === undefined ||
                                item.title === null ||
                                item.details === "" ||
                                item.details === undefined ||
                                item.details === null ||
                                item.theRisk === "" ||
                                item.theRisk === undefined ||
                                item.theRisk === null
                            )
                        )
                      }
                    >
                      {callingBackend ? (
                        <CircularProgress
                          size={25}
                          sx={{ color: "#22345B", margin: "0 5px" }}
                        />
                      ) : (
                        "Next"
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
