import React, { useState, useEffect } from "react";
import axios from "../../libs/axios";
import { Formik, Form } from "formik";
import TextField from "@mui/material/TextField";
import NewInputFeild from "../../components/common/TextFeild";
import InputFeild from "../../components/common/NewTextFeild";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import "../Assesment/assesment.css";
import { Button } from "@mui/material";
import { useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { validationSchema } from "../../utils/validations/siteSecurityValidation";


export default function SiteSecurity({
  formData,
  handlePreviousPage,
  previouscallingBackend,
  setPreviousCallingBackend,
  handleNextPage,
  callingBackend,
  setCallingBackend,
}) {
  const { state } = useLocation();

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const [otherName, setOtherName] = useState(formData?.site_security?.otherName ? formData?.site_security?.otherName : "Other");

  const initialValues = formData?.site_security?.valuable_commodities
    ? {
        valuable_commodities: formData?.site_security?.valuable_commodities,
        yard_storage: formData?.site_security?.yard_storage,
        gates_fencing_barriers: formData?.site_security?.gates_fencing_barriers,
        lighting: formData?.site_security?.lighting,
        cctv: formData?.site_security?.cctv,
        security_patrols: formData?.site_security?.security_patrols,
        alarms: formData?.site_security?.alarms,
        other: formData?.site_security?.other,
      }
    : {
        valuable_commodities: "",
        yard_storage: "",
        gates_fencing_barriers: "",
        lighting: "",
        cctv: "",
        security_patrols: "",
        alarms: "",
        other: "",
      };
  
  const toolbarOptions = [
    ["bold", "italic", "underline"],
    [{ header: 1 }, { header: 2 }],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }],
  ];
  const module = {
    toolbar: toolbarOptions,
  };
  const submit = async (e) => {
    try {
      setCallingBackend(true);
      await axios.put(`/assesment/site_security/${state.id}`, {
        valuable_commodities: e.valuable_commodities,
        yard_storage: e.yard_storage,
        gates_fencing_barriers: e.gates_fencing_barriers,
        lighting: e.lighting,
        cctv: e.cctv,
        security_patrols: e.security_patrols,
        alarms: e.alarms,
        otherName: otherName,
        other: e.other,
      });
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Site security saved successfully!",
      });
      setTimeout(
        () =>
          setAlert({
            showAlert: false,
            severity: "success",
            message: "Site security saved successfully!",
          }),
        handleNextPage(),
        5000
      );
    } catch (error) {
      if (error.response.data === "Site security already exists") {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Site security already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Site security already exists!",
            }),
          5000
        );
      } else if (error.response.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Unauthorized Access!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Unauthorized Access!",
            }),
          5000
        );
      } else if (error.response.status === 500) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Server Error!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Server Error!",
            }),
          5000
        );
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Site security already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Site security already exists!",
            }),
          5000
        );
      }
    }
  };

  const handleClick = () => {
    setPreviousCallingBackend(true);
    handlePreviousPage();
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={submit}
        enableReinitialize
        validationSchema={validationSchema}
      >
        {({ dirty, isValid }) => {
          return (
            <Form>
              <Grid
                container
                xs={12}
                sx={{
                  padding: "1rem",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="formName">Site Security</p>
                <Grid container xs={10} spacing={1}>
                  <Grid container item xs={12} spacing={1}>
                    <Grid item xs={3}>
                      <p className="inputTextH"></p>{" "}
                    </Grid>

                    <Grid item xs={9}>
                      <p className="inputTextH">Description</p>{" "}
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} spacing={1}>
                    <Grid item xs={3}>
                      <p className="inputTextP">Valuable Commodities</p>{" "}
                    </Grid>
                    {/* {JSON.stringify(
                      formData?.site_security?.valuable_commodities
                    )} */}
                    <Grid item xs={9}>
                      <NewInputFeild
                        name="valuable_commodities"
                        component={TextField}
                        variant
                        fullWidth
                        bgColor="#FFFFFF"
                        margin="dense"
                        multiline
                        rows={5}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} spacing={1}>
                    <Grid item xs={3}>
                      <p className="inputTextP">Yard Storage</p>{" "}
                    </Grid>
                    <Grid item xs={9}>
                      <NewInputFeild
                        name="yard_storage"
                        component={TextField}
                        variant
                        fullWidth
                        bgColor="#FFFFFF"
                        margin="dense"
                        multiline
                        rows={5}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} spacing={1}>
                    <Grid item xs={3}>
                      <p className="inputTextP">Gates/Fencing/Barriers</p>{" "}
                    </Grid>
                    <Grid item xs={9} sx={{ marginBottom: "50px" }}>
                      <NewInputFeild
                        name="gates_fencing_barriers"
                        component={TextField}
                        variant
                        fullWidth
                        bgColor="#FFFFFF"
                        margin="dense"
                        multiline
                        rows={5}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} spacing={1}>
                    <Grid item xs={3}>
                      <p className="inputTextP">Lighting</p>{" "}
                    </Grid>
                    <Grid item xs={9} sx={{ marginBottom: "50px" }}>
                      <NewInputFeild
                        name="lighting"
                        component={TextField}
                        variant
                        fullWidth
                        bgColor="#FFFFFF"
                        margin="dense"
                        multiline
                        rows={5}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} spacing={1}>
                    <Grid item xs={3}>
                      <p className="inputTextP">CCTV</p>{" "}
                    </Grid>
                    <Grid item xs={9} sx={{ marginBottom: "50px" }}>
                      <NewInputFeild
                        name="cctv"
                        component={TextField}
                        variant
                        fullWidth
                        bgColor="#FFFFFF"
                        margin="dense"
                        multiline
                        rows={5}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} spacing={1}>
                    <Grid item xs={3}>
                      <p className="inputTextP">Security Patrols</p>{" "}
                    </Grid>
                    <Grid item xs={9} sx={{ marginBottom: "50px" }}>
                      <NewInputFeild
                        name="security_patrols"
                        component={TextField}
                        variant
                        fullWidth
                        bgColor="#FFFFFF"
                        margin="dense"
                        multiline
                        rows={5}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} spacing={1}>
                    <Grid item xs={3}>
                      <p className="inputTextP">Alarms</p>{" "}
                    </Grid>
                    <Grid item xs={9} sx={{ marginBottom: "50px" }}>
                      <NewInputFeild
                        name="alarms"
                        component={TextField}
                        variant
                        fullWidth
                        bgColor="#FFFFFF"
                        margin="dense"
                        multiline
                        rows={5}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} spacing={1}>
                    <Grid item xs={3}>
                      <InputFeild
                        name="otherName"
                        value={otherName}
                        onChange={(e) => setOtherName(e?.target?.value)}
                        component={TextField}
                        variant
                        fullWidth
                        bgColor="#FFFFFF"
                        margin="dense"
                      />{" "}
                    </Grid>
                    <Grid item xs={9}>
                      <NewInputFeild
                        name="other"
                        component={TextField}
                        variant
                        fullWidth
                        bgColor="#FFFFFF"
                        margin="dense"
                        multiline
                        rows={5}
                      />
                    </Grid>
                  </Grid>
                  {/* <Grid item xs={6} spacing={1}>
                    <pre>
                      {JSON.stringify(formData?.site_security, null, 2)}
                    </pre>
                  </Grid> */}
                  <Grid
                    container
                    item
                    xs={12}
                    sx={{
                      alignContent: "center",
                      justifyContent: "space-between",
                      marginTop: "2rem",
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => handleClick()}
                      sx={{
                        backgroundColor: "#22345B",
                        textTransform: "capitalize",
                        fontFamily: "serif",
                        borderRadius: "8px",
                        ":hover": { backgroundColor: "#22345B" },
                      }}
                      disabled={previouscallingBackend}
                    >
                      {previouscallingBackend ? (
                        <CircularProgress
                          size={25}
                          sx={{ color: "#22345B", margin: "0 5px" }}
                        />
                      ) : (
                        "Previous"
                      )}
                    </Button>
                    <Button
                      variant="contained"
                      type="submit"
                      disabled={!isValid || callingBackend}
                      sx={{
                        backgroundColor: "#22345B",
                        textTransform: "capitalize",
                        fontFamily: "serif",
                        borderRadius: "8px",
                        ":hover": { backgroundColor: "#22345B" },
                      }}
                    >
                      {callingBackend ? (
                        <CircularProgress
                          size={25}
                          sx={{ color: "#22345B", margin: "0 5px" }}
                        />
                      ) : (
                        "Next"
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
