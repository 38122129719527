import React, { useState } from "react";
import axios from "../../libs/axios";
import { Formik, Form } from "formik";
import TextField from "@mui/material/TextField";
import InputFeild from "../../components/common/NewTextFeild";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import "../Assesment/assesment.css";
import { Button } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default function FireDetectionProtection({
  formData,
  handlePreviousPage,
  previouscallingBackend,
  setPreviousCallingBackend,
  handleNextPage,
  callingBackend,
  setCallingBackend,
}) {
  const { state } = useLocation();
  const typeOptions = [
    { name: "Adequate", value: 1 },
    { name: "Improvement Needed", value: 0.85 },
    {
      name: "Inadequate",
      value: 0.7,
    },
    { name: "N/A", value: 1 },
  ];
  const typeOptionsTwo = [
    { name: "Adequate", value: 1 },
    { name: "Improvement Needed", value: 0.9 },
    {
      name: "Inadequate",
      value: 0.8,
    },
    { name: "N/A", value: 1 },
  ];
  const typeOptionsThree = [
    { name: "Adequate", value: 1 },
    { name: "Improvement Needed", value: 0.95 },
    {
      name: "Inadequate",
      value: 0.9,
    },
    { name: "N/A", value: 1 },
  ];

  const fire = [
    {
      id: 0,
      name: "Is there adequate fire detection coverage?",
      type: { name: "", value: 1 },
      pdfname: "Is there adequate fire detection coverage?",
      ri: 16,
    },
    {
      id: 1,
      name: "Is there complete fire protection coverage?",
      type: { name: "", value: 1 },
      pdfname: "Is there complete fire protection coverage?",
      ri: 31,
    },
    {
      id: 2,
      name: "Is the sprinkler system adequate for the current occupancy?",
      type: { name: "", value: 1 },
      pdfname: "Is the sprinkler system adequate for the current occupancy?",
      ri: 32,
    },
    {
      id: 3,
      name: "Is existing water supply adequate for installed or recommended sprinkler protection?",
      type: { name: "", value: 1 },
      pdfname:
        "Is existing water supply adequate for installed or recommended sprinkler protection?",
      ri: 33,
    },
    {
      id: 4,
      name: "Are all fire protection control valves locked in the open position?",
      type: { name: "", value: 1 },
      pdfname:
        "Are all fire protection control valves locked in the open position?",
      ri: 17,
    },
    {
      id: 5,
      name: "Have flow tests been analysed and conducted appropriately?",
      type: { name: "", value: 1 },
      pdfname: "Have flow tests been analysed and conducted appropriately?",
      ri: 20,
    },
    {
      id: 6,
      name: "Is there adequate on-site hydrant coverage?",
      type: { name: "", value: 1 },
      pdfname: "Is there adequate on-site hydrant coverage?",
      ri: 18,
    },
    {
      id: 7,
      name: "Is the hydrant system's water supply adequate?",
      type: { name: "", value: 1 },
      pdfname: "Is the hydrant system's water supply adequate?",
      ri: 19,
    },
    {
      id: 8,
      name: "Is fire protection maintenance adequate?",
      type: { name: "", value: 1 },
      pdfname: "Is fire protection maintenance adequate?",
      ri: 21,
    },
    {
      id: 9,
      name: "Is fire extingushing equipment and special protection adequate for the occupancy?",
      type: { name: "", value: 1 },
      pdfname:
        "Is fire extingushing equipment and special protection adequate for the occupancy?",
      ri: 4,
    },
  ];

  const toolbarOptions = [
    ["bold", "italic", "underline"],
    [{ header: 1 }, { header: 2 }],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }],
  ];

  const module = {
    toolbar: toolbarOptions,
  };

  const [arr, setArr] = useState(
    formData?.fire_detection_protection?.f_d_protection.length
      ? [...formData?.fire_detection_protection?.f_d_protection]
      : fire
  );
  const [description, setDescription] = useState(
    formData?.fire_detection_protection?.description
      ? formData?.fire_detection_protection?.description
      : ""
  );
  const changeFormData = (value, name, id) => {
    console.log("id", id);
    const objIndex = arr?.findIndex((obj) => obj?.id === id);
    arr[objIndex][name] = value;

    setArr([...arr]);
  };

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const submit = async (e) => {
    const availableScore = arr.reduce(
      (n, { type }) => n * Number(type?.value),
      1
    );
    console.log("type", availableScore * 30);
    try {
      setCallingBackend(true);
      await axios.put(`/assesment/fire_detection_protection/${state.id}`, {
        f_d_protection: arr,
        description: description,
        available_score: Math.round(availableScore * 30),
      });
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Fire Detection & Protection created successfully!",
      });
      setTimeout(
        () =>
          setAlert({
            showAlert: false,
            severity: "success",
            message: "Fire Detection & Protection created successfully!",
          }),
        handleNextPage(),
        5000
      );
    } catch (error) {
      if (
        error.response.data === "Fire Detection & Protection already exists"
      ) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Fire Detection & Protection already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Fire Detection & Protection already exists!",
            }),
          5000
        );
      } else if (error.response.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Unauthorized Access!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Unauthorized Access!",
            }),
          5000
        );
      } else if (error.response.status === 500) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Server Error!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Server Error!",
            }),
          5000
        );
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Fire Detection & Protection already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Fire Detection & Protection already exists!",
            }),
          5000
        );
      }
    }
  };
  const allTypeNamesFilled = arr.some((item) => item?.type?.name === "");

  console.log("allTypeNamesFilled", allTypeNamesFilled);
  const handleClick = () => {
    setPreviousCallingBackend(true);
    handlePreviousPage();
  };

  return (
    <div>
      <Formik
        initialValues={fire}
        onSubmit={submit}
        // validationSchema={validationSchema}
        enableReinitialize
      >
        {({ dirty, isValid }) => {
          return (
            <Form>
              <Grid
                container
                xs={12}
                sx={{
                  padding: "1rem",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="formName">Fire Detection and Protection</p>
                <Grid container xs={10} spacing={1}>
                  {arr.map((item, i) => {
                    return (
                      <>
                        <Grid item xs={12} sx={{ marginTop: "1.5rem" }}>
                          <p className="font">{item?.name}</p>
                          {item?.id === 0 || item?.id === 5 ? (
                            <Autocomplete
                              hiddenLabel
                              id="type"
                              name="type"
                              size="small"
                              sx={{ marginBottom: "5px" }}
                              value={item?.type}
                              options={typeOptions}
                              getOptionLabel={(option) => option.name}
                              onChange={(event, newValue) => {
                                changeFormData(newValue, "type", item.id);
                              }}
                              renderInput={(params) => (
                                <TextField size="small" {...params} />
                              )}
                            />
                          ) : item?.id === 1 || item?.id === 6 ? (
                            <Autocomplete
                              hiddenLabel
                              id="type"
                              name="type"
                              size="small"
                              sx={{ marginBottom: "5px" }}
                              value={item?.type}
                              options={typeOptionsThree}
                              getOptionLabel={(option) => option.name}
                              onChange={(event, newValue) => {
                                changeFormData(newValue, "type", item.id);
                              }}
                              renderInput={(params) => (
                                <TextField size="small" {...params} />
                              )}
                            />
                          ) : (
                            <Autocomplete
                              hiddenLabel
                              id="type"
                              name="type"
                              size="small"
                              sx={{ marginBottom: "5px" }}
                              value={item?.type}
                              options={typeOptionsTwo}
                              getOptionLabel={(option) => option.name}
                              onChange={(event, newValue) => {
                                changeFormData(newValue, "type", item.id);
                              }}
                              renderInput={(params) => (
                                <TextField size="small" {...params} />
                              )}
                            />
                          )}
                        </Grid>
                      </>
                    );
                  })}
                  <Grid
                    item
                    xs={12}
                    sx={{ marginBottom: "50px", marginTop: "20px" }}
                  >
                    <p className="inputTextH">
                      PRS Area - Fire Detection and Protection Comments
                    </p>
                    <ReactQuill
                      modules={module}
                      theme="snow"
                      value={description}
                      placeholder="Internal use only - up to 500 words"
                      style={{
                        marginBottom: "25px",
                        marginTop: "20px",
                        height: "300px",
                      }}
                      onChange={setDescription}
                    />
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    sx={{
                      alignContent: "center",
                      justifyContent: "space-between",
                      marginTop: "2rem",
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => handleClick()}
                      sx={{
                        backgroundColor: "#22345B",
                        textTransform: "capitalize",
                        fontFamily: "serif",
                        borderRadius: "8px",
                        ":hover": { backgroundColor: "#22345B" },
                      }}
                      disabled={previouscallingBackend}
                    >
                      {previouscallingBackend ? (
                        <CircularProgress
                          size={25}
                          sx={{ color: "#22345B", margin: "0 5px" }}
                        />
                      ) : (
                        "Previous"
                      )}
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#22345B",
                        textTransform: "capitalize",
                        fontFamily: "serif",
                        borderRadius: "8px",
                        ":hover": { backgroundColor: "#22345B" },
                      }}
                      type="submit"
                      disabled={
                        callingBackend ||
                        arr.some(
                          (item) =>
                            item?.type?.name === "" ||
                            item?.type?.name === null ||
                            item?.type?.name === undefined
                        )
                      }
                    >
                      {callingBackend ? (
                        <CircularProgress
                          size={25}
                          sx={{ color: "#22345B", margin: "0 5px" }}
                        />
                      ) : (
                        "Next"
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
