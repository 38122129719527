import React, { useState } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
// import CloseIcon from "@mui/icons-material/Close";
import axios from "../../libs/axios";
import { Formik, Form } from "formik";
import TextField from "@mui/material/TextField";
import InputFeild from "../../components/common/TextFeild";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import "../Assesment/assesment.css";
import CloseIcon from "@material-ui/icons/Close";
import { validationSchema } from "../../utils/validations/assessmentValidation";
import CircularProgress from "@mui/material/CircularProgress";
import Autocomplete from "@mui/material/Autocomplete";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const countries = ["Australia", "New Zealand"];
const Australiasate = [
  "NSW",
  "ACT",
  "Victoria",
  "Queensland",
  "South Australia",
  "Western Australia",
  "Northern Territory",
];
const ACTlocation = ["Greater Canberra and ACT"];
const NSWlocation = ["Sydney Metro", "Regional NSW"];
const Victorialocation = ["Melbourne", "Victoria Regional"];
const Queenslandlocation = ["Brisbane Metro", "QLD Regional"];
const SouthAustralialocation = ["Adelaide", "South Australia Regional"];
const Westernlocation = ["Perth", "Western Australia Regional"];
const Northernlocation = [
  "Darwin",
  "Alice Springs",
  "Northern Territory Regional",
];
const Newzealandlocation = ["New Zealand Metro", "New Zealand Regional"];

const Newzealandsate = ["New Zealand"];

export default function CustomizedDialogs({ loadData }) {
  const [open, setOpen] = React.useState(false);
  const [wlecheck, setWlecheck] = useState(false);
  const [watersupplycheck, setWaterspplycheck] = useState(false);
  const [waterhydrantcheck, setWaterhydraantcheck] = useState(false);
  const [callingBackend, setCallingBackend] = useState(false);
  const [ccountry, setCountry] = useState("");
  const [sstate, setState] = useState("");
  const [llocation, setLocation] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handlewle = (event) => {
    setWlecheck(event.target.checked);
  };
  const handlewatersupply = (event) => {
    setWaterspplycheck(event.target.checked);
  };
    const handlewaterHydrant = (event) => {
      setWaterhydraantcheck(event.target.checked);
    };
  const initialValues = {
    company_name: "",
    company_contact_first_name: "",
    company_contact_last_name: "",
    company_contact_email: "",
    company_contact_number: "",
    country: "",
    state: "",
    location: "",
    wle_exclude: false,
    water_supply_exclude: false,
    hysrant_water_supply_exclude: false,
  };
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });
  const submit = async (e, { resetForm }) => {
    try {
      setCallingBackend(true);
      await axios.post("/assesment/", {
        company_name: e.company_name,
        company_contact_first_name: e.company_contact_first_name,
        company_contact_last_name: e.company_contact_last_name,
        company_contact_email: e.company_contact_email,
        company_contact_number: e.company_contact_number,
        wle_exclude: wlecheck,
        water_supply_exclude: watersupplycheck,
        hysrant_water_supply_exclude: waterhydrantcheck,
        country: ccountry,
        state: sstate,
        location: llocation,
      });
      resetForm();
      loadData();
      handleClose();
      setCountry("");
      setLocation("");
      setState("");
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Assessment created successfully!",
      });
      setTimeout(
        () =>
          setAlert({
            showAlert: false,
            severity: "success",
            message: "Assessment created successfully!",
          }),
        5000
      );
      setCallingBackend(false);
    } catch (error) {
      if (error.response.data === "Assessment already exists") {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Assessment already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Assessment already exists!",
            }),
          5000
        );
      } else if (error.response.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Unauthorized Access!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Unauthorized Access!",
            }),
          5000
        );
      } else if (error.response.status === 500) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Server Error!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Server Error!",
            }),
          5000
        );
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Assessment already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Assessment already exists!",
            }),
          5000
        );
      }
    } finally {
      setCallingBackend(false);
    }
  };


  return (
    <React.Fragment>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        sx={{
          backgroundColor: "#22345B",
          textTransform: "capitalize",
          fontFamily: "serif",
          borderRadius: "8px",
          color: "#ffffff",
          ":hover": { backgroundColor: "#22345B" },
        }}
      >
        Create
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <IconButton
          aria-label="close"
          onClick={ handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <div>
          <Formik
            initialValues={initialValues}
            onSubmit={submit}
            validationSchema={validationSchema}
            enableReinitialize
          >
            {({ dirty, isValid }) => {
              return (
                <Form>
                  <Grid
                    container
                    xs={12}
                    sx={{
                      padding: "1rem",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <p className="formName">Create an assessment</p>
                    <Grid container xs={10} spacing={1}>
                      <Grid item xs={6}>
                        <p className="inputTextP">Company Name</p>
                        <InputFeild
                          name="company_name"
                          component={TextField}
                          bgColor="#FFFFFF"
                          variant
                          fullWidth
                          margin="dense"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <p className="inputTextP">Email</p>
                        <InputFeild
                          name="company_contact_email"
                          component={TextField}
                          bgColor="#FFFFFF"
                          variant
                          fullWidth
                          margin="dense"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <p className="inputTextP">Contact Number</p>
                        <InputFeild
                          name="company_contact_number"
                          component={TextField}
                          variant
                          fullWidth
                          bgColor="#FFFFFF"
                          margin="dense"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <p className="inputTextP">Contact First Name</p>
                        <InputFeild
                          name="company_contact_first_name"
                          component={TextField}
                          variant
                          fullWidth
                          bgColor="#FFFFFF"
                          margin="dense"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <p className="inputTextP">Contact Last Name</p>
                        <InputFeild
                          name="company_contact_last_name"
                          component={TextField}
                          variant
                          fullWidth
                          bgColor="#FFFFFF"
                          margin="dense"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <p className="inputTextP">Country</p>
                        <Autocomplete
                          hiddenLabel
                          id="country"
                          name="country"
                          size="small"
                          value={ccountry || initialValues?.country || ""}
                          options={countries}
                          getOptionLabel={(option) => option}
                          onChange={(event, newValue) => {
                            setCountry(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField size="small" {...params} />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <p className="inputTextP">State</p>
                        <Autocomplete
                          hiddenLabel
                          id="state"
                          name="state"
                          size="small"
                          disabled={!ccountry}
                          value={sstate || initialValues?.state || ""}
                          options={
                            ccountry === "New Zealand"
                              ? Newzealandsate
                              : Australiasate
                          }
                          getOptionLabel={(option) => option}
                          onChange={(event, newValue) => {
                            setState(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField size="small" {...params} />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <p className="inputTextP">Location</p>
                        <Autocomplete
                          hiddenLabel
                          id="location"
                          name="location"
                          size="small"
                          disabled={!ccountry || !sstate}
                          value={llocation || initialValues?.state || ""}
                          options={
                            sstate === "New Zealand"
                              ? Newzealandlocation
                              : sstate === "Northern Territory"
                              ? Northernlocation
                              : sstate === "Western Australia"
                              ? Westernlocation
                              : sstate === "South Australia"
                              ? SouthAustralialocation
                              : sstate === "Queensland"
                              ? Queenslandlocation
                              : sstate === "Victoria"
                              ? Victorialocation
                              : sstate === "ACT"
                              ? ACTlocation
                              : NSWlocation
                          }
                          getOptionLabel={(option) => option}
                          onChange={(event, newValue) => {
                            setLocation(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField size="small" {...params} />
                          )}
                        />
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        sx={{
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={wlecheck}
                              onChange={handlewle}
                              name="wle_exclude"
                            />
                          }
                        />
                        <p className="inputTextP">Exclude WLE</p>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        sx={{
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={watersupplycheck}
                              onChange={handlewatersupply}
                              name="water_supply_exclude"
                            />
                          }
                        />
                        <p className="inputTextP">Exclude Water Flow Chart</p>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        sx={{
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={waterhydrantcheck}
                              onChange={handlewaterHydrant}
                              name="hysrant_water_supply_exclude"
                            />
                          }
                        />
                        <p className="inputTextP">Exclude Hydrant Flow Chart</p>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        sx={{
                          alignContent: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          variant="contained"
                          type="submit"
                          sx={{
                            backgroundColor: "#22345B",
                            textTransform: "capitalize",
                            fontFamily: "serif",
                            borderRadius: "8px",
                            ":hover": { backgroundColor: "#22345B" },
                          }}
                          disabled={
                            !dirty ||
                            !isValid ||
                            callingBackend ||
                            !ccountry ||
                            !sstate ||
                            !llocation
                          }
                        >
                          {callingBackend ? (
                            <CircularProgress
                              size={25}
                              sx={{ color: "#22345B", margin: "0 5px" }}
                            />
                          ) : (
                            "Create"
                          )}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </div>
      </BootstrapDialog>
    </React.Fragment>
  );
}
