import React, { useState } from "react";
import axios from "../../libs/axios";
import { Formik, Form } from "formik";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import "../Assesment/assesment.css";
import { Button } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";



export default function NaturalHazardsRecommendation({
  formData,
  handlePreviousPage,
  previouscallingBackend,
  setPreviousCallingBackend,
  handleNextPage,
  callingBackend,
  setCallingBackend,
}) {
  const typeOptions = [
    { name: "No Risk Improvements", value: 1 },
    { name: "One Risk Improvement", value: 0.9 },
    {
      name: "Two or More Risk Improvements",
      value: 0.8,
    },
  ];
  const natural_hazard = [ 
    {
      id: 0,
      name: "Flood",
      type: { name: "", value: 1 },
      ri: 26,
    },
    {
      id: 1,
      name: "Surface Water",
      type: { name: "", value: 1 },
      ri: 26,
    },
    {
      id: 2,
      name: "Windstorm",
      type: { name: "", value: 1 },
      ri: 27,
    },
    {
      id: 3,
      name: "Cyclone",
      type: { name: "", value: 1 },
      ri: 27,
    },
    {
      id: 4,
      name: "Hail if there is an asbestos roof",
      type: { name: "", value: 1 },
      ri: 28,
    },
    {
      id: 5,
      name: "Bushfire",
      type: { name: "", value: 1 },
      ri: 29,
    },
    {
      id: 6,
      name: "Earthquake",
      type: { name: "", value: 1 },
      ri: 30,
    },
    {
      id: 7,
      name: "Snow Loading",
      type: { name: "", value: 1 },
      ri: 1,
    },
  ];
  const { state } = useLocation();
  const [arr, setArr] = useState(
    formData?.natural_hazards_recommendation?.n_h_recommendation
      ?.length ? [...formData?.natural_hazards_recommendation?.n_h_recommendation] : natural_hazard
  );
  const changeFormData = (value, name, id) => {
    console.log("id", id);
    const objIndex = arr?.findIndex((obj) => obj?.id === id);
    arr[objIndex][name] = value;

    setArr([...arr]);
  };

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const submit = async (e) => {
    const availableScore = arr.reduce(
      (n, { type }) => n * Number(type?.value),
      1
    );
    console.log("type", availableScore * 20);
    try {
      setCallingBackend(true);
      await axios.put(`/assesment/natural_hazards_recommendation/${state.id}`, {
        n_h_recommendation: arr,
        available_score: Math.round(availableScore * 20),
      });
      setTimeout(
        () =>
        handleNextPage(),
        3000
      );
    } catch (error) {
      if (
        error.response.data === "Natural Hazards Recommendation already exists"
      ) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Natural Hazards Recommendation already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Natural Hazards Recommendation already exists!",
            }),
          5000
        );
      } else if (error.response.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Unauthorized Access!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Unauthorized Access!",
            }),
          5000
        );
      } else if (error.response.status === 500) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Server Error!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Server Error!",
            }),
          5000
        );
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Natural Hazards Recommendation already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Natural Hazards Recommendation already exists!",
            }),
          5000
        );
      }
    }
  };

  const handleClick = () => {
    setPreviousCallingBackend(true);
    handlePreviousPage();
  };

  return (
    <div>
      <Formik
        initialValues={natural_hazard}
        onSubmit={submit}
        // validationSchema={validationSchema}
        enableReinitialize
      >
        {({ dirty, isValid }) => {
          return (
            <Form>
              <Grid
                container
                xs={12}
                sx={{
                  padding: "1rem",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="formName">Natural Hazards Recommendation</p>
                <Grid container xs={10} spacing={1}>
                  {arr.map((item, i) => {
                    return (
                      <>
                        <Grid item xs={12} sx={{ marginTop: "1.5rem" }}>
                          <p className="font">{item?.name}</p>
                          <Autocomplete
                            hiddenLabel
                            id="type"
                            name="type"
                            size="small"
                            sx={{ marginBottom: "5px" }}
                            value={item?.type}
                            options={typeOptions}
                            getOptionLabel={(option) => option.name}
                            onChange={(event, newValue) => {
                              changeFormData(newValue, "type", item.id);
                            }}
                            renderInput={(params) => (
                              <TextField size="small" {...params} />
                            )}
                          />
                          {/* <InputFeild
                            name="description"
                            value={item?.description}
                            Placeholder={"Enter up to 200 words"}
                            rows={3}
                            multiline
                            onChange={(e) =>
                              changeFormData(
                                e?.target?.value,
                                "description",
                                item?.id
                              )
                            }
                            component={TextField}
                            variant
                            fullWidth
                            bgColor="#FFFFFF"
                            margin="dense"
                          />{" "} */}
                        </Grid>
                      </>
                    );
                  })}
                  <Grid
                    container
                    item
                    xs={12}
                    sx={{
                      alignContent: "center",
                      justifyContent: "space-between",
                      marginTop: "2rem",
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => handleClick()}
                      sx={{
                        backgroundColor: "#22345B",
                        textTransform: "capitalize",
                        fontFamily: "serif",
                        borderRadius: "8px",
                        ":hover": { backgroundColor: "#22345B" },
                      }}
                      disabled={previouscallingBackend}
                    >
                      {previouscallingBackend ? (
                        <CircularProgress
                          size={25}
                          sx={{ color: "#22345B", margin: "0 5px" }}
                        />
                      ) : (
                        "Previous"
                      )}
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#22345B",
                        textTransform: "capitalize",
                        fontFamily: "serif",
                        borderRadius: "8px",
                        ":hover": { backgroundColor: "#22345B" },
                      }}
                      type="submit"
                      disabled={
                        callingBackend ||
                        arr.some(
                          (item) =>
                            item?.type?.name === "" ||
                            item?.type?.name === null ||
                            item?.type?.name === undefined
                        )
                      }
                    >
                      {callingBackend ? (
                        <CircularProgress
                          size={25}
                          sx={{ color: "#22345B", margin: "0 5px" }}
                        />
                      ) : (
                        "Next"
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
