import React, { useState } from "react";
import axios from "../../libs/axios";
import { Formik, Form } from "formik";
import TextField from "@mui/material/TextField";
import InputFeild from "../../components/common/NewTextFeild";
import Grid from "@mui/material/Grid";
import "../Assesment/assesment.css";
import { Button } from "@mui/material";
// import Autocomplete from "@mui/material/Autocomplete";
import { useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

// const RateType = ["Low Risk", "Medium Risk", "High Risk", "Very High Risk"];

export default function AdditionalDetails({
  formData,
  handlePreviousPage,
  previouscallingBackend,
  setPreviousCallingBackend,
  handleNextPage,
  callingBackend,
  setCallingBackend,
}) {
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });
  const toolbarOptions = [
    ["bold", "italic", "underline"],
    [{ header: 1 }, { header: 2 }],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }],
  ];
  const module = {
    toolbar: toolbarOptions,
  };
  const [number, setNumber] = useState(
    formData?.additional?.anzic?.length
      ? formData?.additional?.anzic?.length
      : 1
  );
  const inputArr = [
    {
      id: 0,
      industry_description: "",
      ANZICcode: "",
    },
  ];
  const [anzic, setAnzic] = useState(
    formData?.additional?.anzic?.length ? formData?.additional?.anzic : inputArr
  );
  // const [ANZICcode, setANZICcode] = useState(
  //   formData?.additional?.ANZICcode ? formData?.additional?.ANZICcode : ""
  // );
  // const [industrydescription, setIndustrydescription] = useState(
  //   formData?.additional?.industry_description
  //     ? formData?.additional?.industry_description
  //     : ""
  // );
  const { state } = useLocation();
  const addInput = () => {
    setNumber(number + 1);
    setAnzic((s) => {
      return [
        ...s,
        {
          id: number,
          industry_description: "",
          ANZICcode: "",
        },
      ];
    });
  };
  const changeFormData = (value, name, id) => {
    const objIndex = anzic?.findIndex((obj) => obj?.id === id);
    anzic[objIndex][name] = value;

    setAnzic([...anzic]);
  };
  const submit = async () => {
    try {
      setCallingBackend(true);
      await axios.put(`/assesment/additional_details/${state.id}`, {
        anzic: anzic,
      });
      setAlert({
        showAlert: true,
        severity: "success",
        message: "ANZIC Classifications saved successfully!",
      });
      setTimeout(
        () =>
          setAlert({
            showAlert: false,
            severity: "success",
            message: "ANZIC Classifications saved successfully!",
          }),
        handleNextPage(),
        5000
      );
    } catch (error) {
      if (error.response.data === "ANZIC Classifications already exists") {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "ANZIC Classifications already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "ANZIC Classifications already exists!",
            }),
          5000
        );
      } else if (error.response.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Unauthorized Access!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Unauthorized Access!",
            }),
          5000
        );
      } else if (error.response.status === 500) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Server Error!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Server Error!",
            }),
          5000
        );
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "ANZIC Classifications already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "ANZIC Classifications already exists!",
            }),
          5000
        );
      }
    }
  };

  const handleClick = () => {
    setPreviousCallingBackend(true);
    handlePreviousPage();
  };

  return (
    <div>
      <Formik enableReinitialize>
        {({ dirty, isValid }) => {
          return (
            <Form>
              <Grid
                container
                xs={12}
                sx={{
                  padding: "1rem",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="formName">
                  Potential ANZIC Classifications for Placement
                </p>
                <Grid container xs={10} spacing={1}>
                  <Grid container item xs={12} spacing={1}>
                    {/* <Grid item xs={12}>
                      <p className="inputTextP">ANZIC code</p>{" "}
                    </Grid>
                    <Grid item xs={12}>
                      <InputFeild
                        name="ANZIC code"
                        value={ANZICcode}
                        onChange={(e) => setANZICcode(e?.target?.value)}
                        component={TextField}
                        variant
                        fullWidth
                        bgColor="#FFFFFF"
                        margin="dense"
                      />{" "}
                    </Grid>
                    <Grid item xs={12}>
                      <p className="inputTextP">Industry Description</p>{" "}
                    </Grid>
                    <Grid item xs={12} sx={{ marginBottom: "100px" }}>
                      <ReactQuill
                        modules={module}
                        theme="snow"
                        value={industrydescription}
                        style={{ marginBottom: "25px", height: "200px" }}
                        onChange={setIndustrydescription}
                      />
                    </Grid> */}
                    {/* <Grid item xs={12}>
                      <p className="inputTextP">Rating Class</p>{" "}
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        hiddenLabel
                        id="rate_class"
                        name="rate_class"
                        size="small"
                        value={item.rate_class}
                        options={RateType}
                        getOptionLabel={(option) => option}
                        onChange={(event, newValue) => {
                          changeFormData(newValue, "rate_class", item.id);
                        }}
                        renderInput={(params) => (
                          <TextField size="small" {...params} />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <p className="inputTextP">Enter Industry</p>{" "}
                    </Grid>
                    <Grid item xs={12}>
                      <InputFeild
                        name="industry"
                        value={item.industry}
                        onChange={(e) => {
                          changeFormData(e.target.value, "industry", item.id);
                        }}
                        component={TextField}
                        variant
                        fullWidth
                        bgColor="#FFFFFF"
                        margin="dense"
                      />{" "}
                    </Grid> */}
                    {anzic?.map((item, i) => {
                      return (
                        <>
                          <Grid item xs={12}>
                            <p className="inputTextP">ANZIC code</p>{" "}
                          </Grid>
                          <Grid item xs={12}>
                            {/* <Autocomplete
                              hiddenLabel
                              id="ANZICcode"
                              name="ANZICcode"
                              size="small"
                              value={item.ANZICcode}
                              options={RateType}
                              getOptionLabel={(option) => option}
                              onChange={(event, newValue) => {
                                changeFormData(newValue, "ANZICcode", item.id);
                              }}
                              renderInput={(params) => (
                                <TextField size="small" {...params} />
                              )}
                            /> */}
                            <InputFeild
                              name="ANZICcode"
                              value={item.ANZICcode}
                              onChange={(e) => {
                                changeFormData(
                                  e.target.value,
                                  "ANZICcode",
                                  item.id
                                );
                              }}
                              component={TextField}
                              variant
                              fullWidth
                              bgColor="#FFFFFF"
                              margin="dense"
                            />{" "}
                          </Grid>
                          <Grid item xs={12}>
                            <p className="inputTextP">Industry Description</p>{" "}
                          </Grid>
                          <Grid item xs={12} sx={{ marginBottom: "50px" }}>
                            <ReactQuill
                              modules={module}
                              theme="snow"
                              value={item?.industry_description}
                              style={{ marginBottom: "25px", height: "200px" }}
                              onChange={(e) => {
                                changeFormData(
                                  e,
                                  "industry_description",
                                  item.id
                                )
                              }}
                            />
                          </Grid>
                        </>
                      );
                    })}
                    <Grid container xs={10} spacing={1}>
                      <Button
                        onClick={addInput}
                        variant="outlined"
                        sx={{
                          textTransform: "capitalize",
                          marginTop: "1.5rem",
                          marginLeft: "0?.5rem",
                        }}
                        disabled={
                          number >= 4 ||
                          !anzic?.every(
                            (item) =>
                              !Object?.entries(item)?.some(
                                ([key, value]) => value === ""
                              )
                          )
                        }
                      >
                        Add Field
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    sx={{
                      alignContent: "center",
                      justifyContent: "space-between",
                      marginTop: "2rem",
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => handleClick()}
                      sx={{
                        backgroundColor: "#22345B",
                        textTransform: "capitalize",
                        fontFamily: "serif",
                        borderRadius: "8px",
                        ":hover": { backgroundColor: "#22345B" },
                      }}
                      disabled={previouscallingBackend}
                    >
                      {previouscallingBackend ? (
                        <CircularProgress
                          size={25}
                          sx={{ color: "#22345B", margin: "0 5px" }}
                        />
                      ) : (
                        "Previous"
                      )}
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => submit()}
                      sx={{
                        backgroundColor: "#22345B",
                        textTransform: "capitalize",
                        fontFamily: "serif",
                        borderRadius: "8px",
                        ":hover": { backgroundColor: "#22345B" },
                      }}
                      disabled={
                        callingBackend ||
                        !anzic?.every(
                          (item) =>
                            !Object?.entries(item)?.some(
                              ([key, value]) => value === ""
                            )
                        )
                      }
                    >
                      {callingBackend ? (
                        <CircularProgress
                          size={25}
                          sx={{ color: "#22345B", margin: "0 5px" }}
                        />
                      ) : (
                        "Next"
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
