import React, { useState, useEffect } from "react";
import axios from "../../libs/axios";
import { Formik, Form } from "formik";
import TextField from "@mui/material/TextField";
import InputFeild from "../../components/common/TextFeild";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import "../Assesment/assesment.css";
import { Button } from "@mui/material";
import { useLocation } from "react-router-dom";
import { validationSchema } from "../../utils/validations/assessmentAditionalValidation";
import CircularProgress from "@mui/material/CircularProgress";
import Autocomplete from "@mui/material/Autocomplete";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";


const countries = ["Australia", "New Zealand"];
const Australiasate = [
  "NSW",
  "ACT",
  "Victoria",
  "Queensland",
  "South Australia",
  "Western Australia",
  "Northern Territory",
];
const Newzealandsate = ["New Zealand"];

const ACTlocation = ["Greater Canberra and ACT"];
const NSWlocation = ["Sydney Metro", "Regional NSW"];
const Victorialocation = ["Melbourne", "Victoria Regional"];
const Queenslandlocation = ["Brisbane Metro", "QLD Regional"];
const SouthAustralialocation = ["Adelaide", "South Australia Regional"];
const Westernlocation = ["Perth", "Western Australia Regional"];
const Northernlocation = [
  "Darwin",
  "Alice Springs",
  "Northern Territory Regional",
];
const Newzealandlocation = ["New Zealand Metro", "New Zealand Regional"];
const Titles = [
  "Principle Risk Engineer",
  "Risk Engineer",
];
const PersonTitles = ["Mr.", "Mrs.", "Ms.", "Miss.", "Dr."];
export default function AditionalAssessment({
  handleNextPage,
  formData,
  callingBackend,
  setCallingBackend,
}) {
    const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
      width: "100%", // Ensures the DatePicker takes full width
      "& .MuiInputBase-root": {
        // Apply styles to the input root
        backgroundColor: "#FFFFFF",
        paddingTop: 0,
        marginTop: "-8px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        width: "100%", // Ensures the input base takes full width
      },
      "& .MuiInputBase-input": {
        // Apply styles to the input text
        fontSize: 16,
        width: "100%", // Ensures the input text takes full width
        padding: "9px",
      },
    }));
  
  const { state } = useLocation();
  const [contactTitle, setContactTitle] = useState("");
  const [riskContactTitle, setRiskContactTitle] = useState("");
  const [ccountry, setCountry] = useState("");
  const [sstate, setState] = useState("");
    const [testdate, setTestdate] = useState("");
  const [llocation, setLocation] = useState("");
  const [initialValues, setInitialValues] = useState({
    company_name: "",
    company_abn: "",
    company_email: "",
    company_contact_first_name: "",
    company_contact_last_name: "",
    company_contact_job: "",
    company_contact_email: "",
    company_contact_number: "",
    unit_no: "",
    street_name: "",
    suburb: "",
    country: "",
    location: "",
    state: "",
    zip: "",
    sitevisit_date: "",
    assigned_engineer: "",
    broker_contact_name: "",
    broker_contact_job: "",
    broker_contact_company: "",
    wle_exclude: "",
    water_supply_exclude: "",
    hysrant_water_supply_exclude: "",
    site_title:"",
  });
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });
  const submit = async (e) => {
    try {
      setCallingBackend(true);
      await axios.put(`/assesment/additional-assessment-details/${state.id}`, {
        company_name: e.company_name,
        company_abn: e.company_abn,
        company_email: e.company_email,
        company_contact_title: contactTitle,
        company_contact_first_name: e.company_contact_first_name,
        company_contact_last_name: e.company_contact_last_name,
        company_contact_job: e.company_contact_job,
        company_contact_email: e.company_contact_email,
        company_contact_number: e.company_contact_number,
        unit_no: e.unit_no,
        street_name: e.street_name,
        suburb: e.suburb,
        country: ccountry,
        state: sstate,
        location: llocation,
        zip: e.zip,
        sitevisit_date: dayjs(testdate).format("YYYY-MM-DD"),
        risk_engineer_title: riskContactTitle,
        assigned_engineer: e.assigned_engineer,
        broker_contact_name: e.broker_contact_name,
        broker_contact_job: e.broker_contact_job,
        broker_contact_company: e.broker_contact_company,
        wle_exclude: e.wle_exclude,
        water_supply_exclude: e.water_supply_exclude,
        hysrant_water_supply_exclude: e.hysrant_water_supply_exclude,
        site_title: e.site_title,
      });
      setCountry("");
      setLocation("");
      setState("");
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Aditional assessment details created successfully!",
      });
      setTimeout(
        () =>
          setAlert({
            showAlert: false,
            severity: "success",
            message: "Aditional assessment details created successfully!",
          }),
        handleNextPage(),
        5000
      );
    } catch (error) {
      if (error.response.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Unauthorized Access!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Unauthorized Access!",
            }),
          5000
        );
      } else if (error.response.status === 500) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Server Error!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Server Error!",
            }),
          5000
        );
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Aditional assessment details already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Aditional assessment details already exists!",
            }),
          5000
        );
      }
    }
  };

  useEffect(() => {
    setInitialValues({
      company_name: formData?.assessment?.company_name,
      company_abn: formData?.assessment?.company_abn,
      company_email: formData?.assessment?.company_email,
      company_contact_first_name:
        formData?.assessment?.company_contact_first_name,
      company_contact_last_name:
        formData?.assessment?.company_contact_last_name,
      company_contact_job: formData?.assessment?.company_contact_job,
      company_contact_email: formData?.assessment?.company_contact_email,
      company_contact_number: formData?.assessment?.company_contact_number,
      unit_no: formData?.assessment?.unit_no,
      street_no: formData?.assessment?.street_no,
      street_name: formData?.assessment?.street_name,
      suburb: formData?.assessment?.suburb,
      country: formData?.assessment?.country,
      location: formData?.assessment?.location,
      state: formData?.assessment?.state,
      zip: formData?.assessment?.zip,
      assigned_engineer: formData?.assessment?.assigned_engineer,
      broker_contact_name: formData?.assessment?.broker_contact_name,
      broker_contact_job: formData?.assessment?.broker_contact_job,
      broker_contact_company: formData?.assessment?.broker_contact_company,
      wle_exclude: formData?.assessment?.wle_exclude,
      water_supply_exclude: formData?.assessment?.water_supply_exclude,
      hysrant_water_supply_exclude:
        formData?.assessment?.hysrant_water_supply_exclude,
      site_title: formData?.assessment?.site_title,
    });
        setTestdate(formData?.assessment?.sitevisit_date);
    setCountry(formData?.assessment?.country);
    setLocation(formData?.assessment?.location);
    setState(formData?.assessment?.state);
    setContactTitle(formData?.assessment?.company_contact_title);
    setRiskContactTitle(formData?.assessment?.risk_engineer_title);
  }, [formData]);

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={submit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ dirty, isValid }) => {
          return (
            <Form>
              <Grid
                container
                xs={12}
                sx={{
                  padding: "1rem",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="formName">Additional Assessment Details</p>
                <Grid container xs={10} spacing={1}>
                  <Grid item xs={6}>
                    <p className="inputTextP">Company Name</p>
                    <InputFeild
                      name="company_name"
                      component={TextField}
                      bgColor="#FFFFFF"
                      variant
                      fullWidth
                      margin="dense"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <p className="inputTextP">Company ABN</p>
                    <InputFeild
                      name="company_abn"
                      component={TextField}
                      variant
                      fullWidth
                      bgColor="#FFFFFF"
                      margin="dense"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <p className="inputTextP">Company Email</p>
                    <InputFeild
                      name="company_email"
                      component={TextField}
                      variant
                      fullWidth
                      bgColor="#FFFFFF"
                      margin="dense"
                    />
                  </Grid>
                </Grid>
                <p className="formName">Site Address</p>
                <Grid container xs={10} spacing={1}>
                  <Grid item xs={6}>
                    <p className="inputTextP">Street No</p>
                    <InputFeild
                      name="unit_no"
                      component={TextField}
                      bgColor="#FFFFFF"
                      variant
                      fullWidth
                      margin="dense"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <p className="inputTextP">Street Name</p>
                    <InputFeild
                      name="street_name"
                      component={TextField}
                      variant
                      fullWidth
                      bgColor="#FFFFFF"
                      margin="dense"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <p className="inputTextP">Country</p>
                    <Autocomplete
                      hiddenLabel
                      id="country"
                      name="country"
                      size="small"
                      value={ccountry || initialValues?.country || ""}
                      options={countries}
                      getOptionLabel={(option) => option}
                      onChange={(event, newValue) => {
                        setCountry(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField size="small" {...params} />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <p className="inputTextP">Suburb</p>
                    <InputFeild
                      name="suburb"
                      component={TextField}
                      variant
                      fullWidth
                      bgColor="#FFFFFF"
                      margin="dense"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <p className="inputTextP">State</p>
                    <Autocomplete
                      hiddenLabel
                      id="state"
                      name="state"
                      size="small"
                      value={sstate || initialValues?.state || ""}
                      options={
                        ccountry === "New Zealand"
                          ? Newzealandsate
                          : Australiasate
                      }
                      getOptionLabel={(option) => option}
                      onChange={(event, newValue) => {
                        setState(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField size="small" {...params} />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <p className="inputTextP">Location</p>
                    <Autocomplete
                      hiddenLabel
                      id="location"
                      name="location"
                      size="small"
                      value={llocation || initialValues?.location || ""}
                      options={
                        sstate === "New Zealand"
                          ? Newzealandlocation
                          : sstate === "Northern Territory"
                          ? Northernlocation
                          : sstate === "Western Australia"
                          ? Westernlocation
                          : sstate === "South Australia"
                          ? SouthAustralialocation
                          : sstate === "Queensland"
                          ? Queenslandlocation
                          : sstate === "Victoria"
                          ? Victorialocation
                          : sstate === "ACT"
                          ? ACTlocation
                          : NSWlocation
                      }
                      getOptionLabel={(option) => option}
                      onChange={(event, newValue) => {
                        setLocation(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField size="small" {...params} />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <p className="inputTextP">Post code</p>
                    <InputFeild
                      name="zip"
                      component={TextField}
                      variant
                      fullWidth
                      bgColor="#FFFFFF"
                      margin="dense"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <p className="inputTextP">Site Title</p>
                    <InputFeild
                      name="site_title"
                      component={TextField}
                      variant
                      fullWidth
                      bgColor="#FFFFFF"
                      margin="dense"
                    />
                  </Grid>
                </Grid>
                <p className="formName">Company Contact Information</p>
                <Grid container xs={10} spacing={1}>
                  <Grid item xs={6}>
                    <p className="inputTextP">Title</p>
                    <Autocomplete
                      hiddenLabel
                      id="company_contact_title"
                      name="company_contact_title"
                      size="small"
                      value={contactTitle}
                      options={PersonTitles}
                      getOptionLabel={(option) => option}
                      onChange={(event, newValue) => {
                        setContactTitle(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField size="small" {...params} />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <p className="inputTextP">First Name</p>
                    <InputFeild
                      name="company_contact_first_name"
                      component={TextField}
                      variant
                      fullWidth
                      bgColor="#FFFFFF"
                      margin="dense"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <p className="inputTextP">Last Name</p>
                    <InputFeild
                      name="company_contact_last_name"
                      component={TextField}
                      variant
                      fullWidth
                      bgColor="#FFFFFF"
                      margin="dense"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <p className="inputTextP">Job Title</p>
                    <InputFeild
                      name="company_contact_job"
                      component={TextField}
                      variant
                      fullWidth
                      bgColor="#FFFFFF"
                      margin="dense"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <p className="inputTextP">Email</p>
                    <InputFeild
                      name="company_contact_email"
                      component={TextField}
                      variant
                      fullWidth
                      bgColor="#FFFFFF"
                      margin="dense"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <p className="inputTextP">Contact Number</p>
                    <InputFeild
                      name="company_contact_number"
                      component={TextField}
                      variant
                      fullWidth
                      bgColor="#FFFFFF"
                      margin="dense"
                    />
                  </Grid>
                </Grid>
                <p className="formName">Assessment General Details</p>
                <Grid container xs={10} spacing={1}>
                  <Grid item xs={6}>
                    <p className="inputTextP">Risk Engineer</p>
                    <InputFeild
                      name="assigned_engineer"
                      component={TextField}
                      variant
                      fullWidth
                      bgColor="#FFFFFF"
                      margin="dense"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <p className="inputTextP">Risk Engineer Title</p>
                    <Autocomplete
                      hiddenLabel
                      id="risk_engineer_title"
                      name="risk_engineer_title"
                      size="small"
                      value={riskContactTitle}
                      options={Titles}
                      getOptionLabel={(option) => option}
                      onChange={(event, newValue) => {
                        setRiskContactTitle(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField size="small" {...params} />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <p className="inputTextP">Date Of Site Visit</p>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker"]}>
                        <StyledDatePicker
                          value={testdate ? dayjs(testdate) : null}
                          onChange={(newValue) => {
                            const formattedDate = newValue
                              ? newValue.format("YYYY-MM-DD")
                              : null;
                            setTestdate(formattedDate);
                          }}
                          format="YYYY-MM-DD"
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <p className="formName">Broker Details</p>
                <Grid container xs={10} spacing={1}>
                  <Grid item xs={6}>
                    <p className="inputTextP">Broker Contact Name</p>
                    <InputFeild
                      name="broker_contact_name"
                      component={TextField}
                      variant
                      fullWidth
                      bgColor="#FFFFFF"
                      margin="dense"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <p className="inputTextP">Broker Job Title</p>
                    <InputFeild
                      name="broker_contact_job"
                      component={TextField}
                      variant
                      fullWidth
                      bgColor="#FFFFFF"
                      margin="dense"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <p className="inputTextP">Broker Company</p>
                    <InputFeild
                      name="broker_contact_company"
                      component={TextField}
                      variant
                      fullWidth
                      bgColor="#FFFFFF"
                      margin="dense"
                    />
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    sx={{
                      alignContent: "center",
                      justifyContent: "flex-end",
                      marginTop: "2rem",
                    }}
                  >
                    <Button
                      variant="contained"
                      type="submit"
                      sx={{
                        backgroundColor: "#22345B",
                        textTransform: "capitalize",
                        fontFamily: "serif",
                        borderRadius: "8px",
                        ":hover": { backgroundColor: "#22345B" },
                      }}
                      disabled={
                        !isValid ||
                        callingBackend ||
                        !contactTitle ||
                        !testdate ||
                        !riskContactTitle
                      }
                    >
                      {callingBackend ? (
                        <CircularProgress
                          size={25}
                          sx={{ color: "#22345B", margin: "0 5px" }}
                        />
                      ) : (
                        "Next"
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
