import React, { useState } from "react";
import axios from "../../libs/axios";
import { Formik, Form } from "formik";
import TextField from "@mui/material/TextField";
import InputFeild from "../../components/common/NewTextFeild";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import "../Assesment/assesment.css";
import { Button } from "@mui/material";
import { validationSchema } from "../../utils/validations/commentValidation";
import Autocomplete from "@mui/material/Autocomplete";
import { useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const labels = ["Adequate", "Inadequate", "Unknown"];

export default function ProtectionOne({
  formData,
  handlePreviousPage,
  previouscallingBackend,
  setPreviousCallingBackend,
  handleNextPage,
  callingBackend,
  setCallingBackend,
  handleTwoNextPage,
}) {
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });
  const { state } = useLocation();

  const inputArr = [
    {
      id: 0,
      building: "",
      area_protection: "",
      percentage_of_site: "",
      existing_design: "",
      required_design: "",
      adequacy: "",
    },
  ];
  const [quillbar, setQuillbar] = useState(formData?.sprinklers?.comment ? formData?.sprinklers?.comment : "");
  const toolbarOptions = [
    ["bold", "italic", "underline"],
    [{ header: 1 }, { header: 2 }],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }],
  ];
  const module = {
    toolbar: toolbarOptions,
  };
  const [arr, setArr] = useState(
    formData?.sprinklers?.protection?.length ? [...formData?.sprinklers?.protection] : inputArr
  );
  const buildingArr = formData?.construction_page?.construction_type.length ? [...formData?.construction_page?.construction_type] : [];
  const [number, setNumber] = useState(formData?.sprinklers?.protection?.length
    ? formData?.sprinklers?.protection?.length
    : 1);
  
  const addInput = () => {
    setNumber(number + 1);
    setArr((s) => {
      return [
        ...s,
        {
          id: number,
          building: "",
          area_protection: "",
          percentage_of_site: "",
          existing_design: "",
          required_design: "",
          adequacy: "",
        },
      ];
    });
  };
  const changeFormData = (value, name, id) => {
    const objIndex = arr.findIndex((obj) => obj.id === id);
    arr[objIndex][name] = value;

    setArr([...arr]);
  };
  const changeCommentData = (value, name, id) => {
    const objIndex = arr?.findIndex((obj) => obj?.id === id);
    arr[objIndex][name] = value;

    setArr([...arr]);
  };
  const submit = async (e) => {
    const filtered = arr?.filter((obj) => obj.adequacy !== "Inadequate");
    const changedArray = buildingArr?.map((item) => {
      let total = 0;
      filtered
        .filter((h) => h.building === item.name)
        .forEach((i) => {
          total += parseFloat(i.percentage_of_site);
        });

      return {
        id: item._id,
        total: total,
      };
    });

    try {
      setCallingBackend(true);
      await axios.put(`/assesment/protection/${state.id}`, {
        protection: arr,
        comment: quillbar,
        percentage: changedArray,
      });
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Protection created successfully!",
      });
       setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "success",
            message: "Protection created successfully!",
          });
         handleNextPage();
        }, 5000);
    } catch (error) {
      if (error.response.data === "Protection already exists") {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Protection already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Protection already exists!",
            }),
          5000
        );
      } else if (error.response.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Unauthorized Access!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Unauthorized Access!",
            }),
          5000
        );
      } else if (error.response.status === 500) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Server Error!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Server Error!",
            }),
          5000
        );
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Protection already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Protection already exists!",
            }),
          5000
        );
      }
    }
  };
  const handleClick = () => {
    setPreviousCallingBackend(true);
    handlePreviousPage();
  };

  return (
    <div>
      <Formik validationSchema={validationSchema} enableReinitialize>
        {({ dirty, isValid }) => {
          return (
            <Form>
              <Grid
                container
                xs={12}
                sx={{
                  padding: "1rem",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="formName">Automatic Sprinkler Systems</p>
                <Grid container xs={10} spacing={1}>
                  <Grid container item xs={12} spacing={1}>
                    {arr?.map((item, i) => {
                      return (
                        <>
                          <Grid container item xs={2.5} spacing={1}>
                            <Grid item xs={12}>
                              <p className="inputTextH">Building</p>{" "}
                            </Grid>
                          </Grid>
                          <Grid container item xs={7} spacing={1}>
                            <Grid item xs={12}>
                              <p className="inputTextH">Area Protection</p>{" "}
                            </Grid>
                          </Grid>
                          <Grid container item xs={1.5} spacing={1}>
                            <Grid item xs={12}>
                              <p className="inputTextH">Percentage Of Site</p>{" "}
                            </Grid>
                          </Grid>
                          <Grid container item xs={2.5} spacing={1}>
                            <Grid item xs={12}>
                              <Autocomplete
                                hiddenLabel
                                id="building"
                                name="building"
                                size="small"
                                value={item?.building || ""}
                                options={
                                  buildingArr.map((obj) => obj.name) || []
                                }
                                getOptionLabel={(option) => option}
                                onChange={(event, newValue) => {
                                  changeFormData(newValue, "building", item.id);
                                }}
                                renderInput={(params) => (
                                  <TextField size="small" {...params} />
                                )}
                              />
                            </Grid>
                          </Grid>
                          <Grid container item xs={7} spacing={1}>
                            <Grid item xs={12}>
                              <InputFeild
                                name="area_protection"
                                value={item.area_protection}
                                onChange={(e) =>
                                  changeFormData(
                                    e.target.value,
                                    "area_protection",
                                    item.id
                                  )
                                }
                                component={TextField}
                                variant
                                fullWidth
                                bgColor="#FFFFFF"
                                margin="dense"
                              />{" "}
                            </Grid>
                          </Grid>
                          <Grid container item xs={2.5} spacing={1}>
                            <Grid item xs={12}>
                              <InputFeild
                                name="percentage_of_site"
                                value={item.percentage_of_site}
                                onChange={(e) =>
                                  changeFormData(
                                    e.target.value,
                                    "percentage_of_site",
                                    item.id
                                  )
                                }
                                component={TextField}
                                variant
                                fullWidth
                                bgColor="#FFFFFF"
                                margin="dense"
                                type="number"
                              />{" "}
                            </Grid>
                          </Grid>
                          {/* <Grid
                            container
                            item
                            xs={2.5}
                            spacing={1}
                            sx={{ alignContent: "flex-start" }}
                          >
                            <Grid item xs={12}>
                              <p className="inputTextH">Area Protection</p>{" "}
                            </Grid>
                            <Grid item xs={12}>
                              <InputFeild
                                name="area_protection"
                                value={item.area_protection}
                                component={TextField}
                                variant
                                fullWidth
                                bgColor="#FFFFFF"
                                margin="dense"
                              />{" "}
                            </Grid>
                          </Grid> */}
                          <Grid
                            container
                            item
                            xs={12}
                            spacing={1}
                            sx={{ alignContent: "flex-start" }}
                          >
                            <Grid item xs={12}>
                              <p className="inputTextH">Existing Design</p>{" "}
                            </Grid>
                            <Grid item xs={12} sx={{ marginBottom: "50px" }}>
                              <ReactQuill
                                modules={module}
                                theme="snow"
                                value={item?.existing_design}
                                style={{
                                  marginBottom: "25px",
                                  height: "50px",
                                }}
                                onChange={(e) => {
                                  changeCommentData(
                                    e,
                                    "existing_design",
                                    item.id
                                  );
                                }}
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            item
                            xs={12}
                            spacing={1}
                            sx={{ alignContent: "flex-start" }}
                          >
                            <Grid item xs={12}>
                              <p className="inputTextH">Required Design</p>{" "}
                            </Grid>
                            <Grid item xs={12} style={{ marginBottom: "50px" }}>
                              <ReactQuill
                                modules={module}
                                theme="snow"
                                value={item?.required_design}
                                style={{
                                  marginBottom: "25px",
                                  height: "50px",
                                }}
                                onChange={(e) => {
                                  changeCommentData(
                                    e,
                                    "required_design",
                                    item.id
                                  );
                                }}
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            item
                            xs={12}
                            spacing={1}
                            style={{ marginBottom: "50px" }}
                          >
                            <Grid item xs={12}>
                              <p className="inputTextH">Adequacy</p>{" "}
                            </Grid>
                            <Grid item xs={12}>
                              <Autocomplete
                                hiddenLabel
                                id="adequacy"
                                name="adequacy"
                                size="small"
                                value={item.adequacy}
                                options={labels}
                                getOptionLabel={(option) => option}
                                onChange={(event, newValue) => {
                                  changeFormData(newValue, "adequacy", item.id);
                                }}
                                renderInput={(params) => (
                                  <TextField size="small" {...params} />
                                )}
                              />
                            </Grid>
                          </Grid>
                        </>
                      );
                    })}
                    <Grid
                      container
                      item
                      xs={12}
                      spacing={1}
                      sx={{
                        justifyContent: "flex-start",
                        alignContent: "flex-start",
                        alignItems: "flex-start",
                      }}
                    ></Grid>
                    <Button
                      onClick={addInput}
                      variant="outlined"
                      sx={{
                        textTransform: "capitalize",
                        marginTop: "1.5rem",
                        marginLeft: "0.5rem",
                      }}
                      disabled={
                        number >= 30 ||
                        !arr.every(
                          (item) =>
                            !Object.entries(item).some(
                              ([key, value]) => value === ""
                            )
                        )
                      }
                    >
                      Add Field
                    </Button>

                    <Grid item xs={12}>
                      <p className="inputTextH">Comments</p>{" "}
                    </Grid>
                    <Grid item xs={12} sx={{ marginBottom: "50px" }}>
                      {/* <InputFeild
                        name="comment"
                        onChange={(e) => setComments(e.target.value)}
                        value={comments || ""}
                        component={TextField}
                        variant
                        fullWidth
                        bgColor="#FFFFFF"
                        margin="dense"
                        multiline
                        rows={10}
                      />{" "} */}
                      <ReactQuill
                        modules={module}
                        theme="snow"
                        value={quillbar}
                        onChange={setQuillbar}
                        style={{ marginBottom: "25px", height: "100px" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    sx={{
                      alignContent: "center",
                      justifyContent: "space-between",
                      marginTop: "2rem",
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => handleClick()}
                      sx={{
                        backgroundColor: "#22345B",
                        textTransform: "capitalize",
                        fontFamily: "serif",
                        borderRadius: "8px",
                        ":hover": { backgroundColor: "#22345B" },
                      }}
                      disabled={previouscallingBackend}
                    >
                      {previouscallingBackend ? (
                        <CircularProgress
                          size={25}
                          sx={{ color: "#22345B", margin: "0 5px" }}
                        />
                      ) : (
                        "Previous"
                      )}
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => submit()}
                      sx={{
                        backgroundColor: "#22345B",
                        textTransform: "capitalize",
                        fontFamily: "serif",
                        borderRadius: "8px",
                        ":hover": { backgroundColor: "#22345B" },
                      }}
                      disabled={
                        !arr?.every(
                          (item) =>
                            !Object?.entries(item)?.some(
                              ([key, value]) => value === "" || undefined
                            )
                        ) ||
                        !isValid ||
                        number === 0 ||
                        callingBackend
                      }
                    >
                      {callingBackend ? (
                        <CircularProgress
                          size={25}
                          sx={{ color: "#22345B", margin: "0 5px" }}
                        />
                      ) : (
                        "Next"
                      )}
                    </Button>
                  </Grid>
                </Grid>
                {/* <Grid container xs={10} spacing={1}>
                  <Grid container item xs={12} spacing={1}>
                    <Grid container item xs={2.5} spacing={1}>
                      <Grid item xs={12}>
                        <p className="inputTextH">Building</p>{" "}
                      </Grid>
                    </Grid>
                    <Grid container item xs={2} spacing={1}>
                      <Grid item xs={12}>
                        <p className="inputTextH">Area Protection</p>{" "}
                      </Grid>
                    </Grid>
                    <Grid container item xs={1.5} spacing={1}>
                      <Grid item xs={12}>
                        <p className="inputTextH">Percentage Of Site</p>{" "}
                      </Grid>
                    </Grid>
                    <Grid container item xs={2} spacing={1}>
                      <Grid item xs={12}>
                        <p className="inputTextH">Existing Design</p>{" "}
                      </Grid>
                    </Grid>
                    <Grid container item xs={2} spacing={1}>
                      <Grid item xs={12}>
                        <p className="inputTextH">Required Design</p>{" "}
                      </Grid>
                    </Grid>
                    <Grid container item xs={2} spacing={1}>
                      <Grid item xs={12}>
                        <p className="inputTextH">Adequacy</p>{" "}
                      </Grid>
                    </Grid>
                    {arr?.map((item, i) => {
                      return (
                        <>
                          <Grid container item xs={2.5} spacing={1}>
                            <Grid item xs={12}>
                              <Autocomplete
                                hiddenLabel
                                id="building"
                                name="building"
                                size="small"
                                value={item?.building || ""}
                                options={
                                  buildingArr.map((obj) => obj.name) || []
                                }
                                getOptionLabel={(option) => option}
                                renderInput={(params) => (
                                  <TextField size="small" {...params} />
                                )}
                              />
                            </Grid>
                          </Grid>
                          <Grid container item xs={9.5} spacing={1}>
                            <Grid item xs={12}>
                              <InputFeild
                                name="existing_design"
                                value={item.existing_design}
                                onChange={(e) =>
                                  changeFormData(
                                    e.target.value,
                                    "existing_design",
                                    item.id
                                  )
                                }
                                component={TextField}
                                variant
                                fullWidth
                                bgColor="#FFFFFF"
                                margin="dense"
                              />{" "}
                            </Grid>
                          </Grid>
                          <Grid container item xs={2.5} spacing={1}>
                            <Grid item xs={12}>
                              <Autocomplete
                                hiddenLabel
                                id="adequacy"
                                name="adequacy"
                                size="small"
                                value={item.adequacy}
                                options={labels}
                                getOptionLabel={(option) => option}
                                onChange={(event, newValue) => {
                                  changeFormData(newValue, "adequacy", item.id);
                                }}
                                renderInput={(params) => (
                                  <TextField size="small" {...params} />
                                )}
                              />
                            </Grid>
                          </Grid>
                          <Grid container item xs={9.5} spacing={1}>
                            <Grid item xs={12}>
                              <InputFeild
                                name="required_design"
                                value={item.required_design}
                                onChange={(e) =>
                                  changeFormData(
                                    e.target.value,
                                    "required_design",
                                    item.id
                                  )
                                }
                                component={TextField}
                                variant
                                fullWidth
                                bgColor="#FFFFFF"
                                margin="dense"
                              />{" "}
                            </Grid>
                          </Grid>
                        </>
                      );
                    })}
                    <Button
                      onClick={addInput}
                      variant="outlined"
                      sx={{
                        textTransform: "capitalize",
                        marginTop: "1.5rem",
                        marginLeft: "0.5rem",
                      }}
                      disabled={
                        number >= 30 ||
                        !arr.every(
                          (item) =>
                            !Object.entries(item).some(
                              ([key, value]) => value === ""
                            )
                        )
                      }
                    >
                      Add Field
                    </Button>

                    <Grid item xs={12}>
                      <p className="inputTextH">Comments</p>{" "}
                    </Grid>
                    <Grid item xs={12}>
                      <InputFeild
                        name="comment"
                        onChange={(e) => setComments(e.target.value)}
                        value={comments || ""}
                        component={TextField}
                        variant
                        fullWidth
                        bgColor="#FFFFFF"
                        margin="dense"
                        multiline
                        rows={10}
                      />{" "}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} spacing={1}>
                    <div styles={{ whiteSpace: "pre-wrap" }}>
                      {JSON.stringify(formData?.sprinklers, null, 2)}
                    </div>
                  </Grid>
                  <Grid item xs={12} spacing={1}>
                    <div styles={{ whiteSpace: "pre-wrap" }}>
                      {JSON.stringify(formData?.construction_page, null, 2)}
                    </div>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    sx={{
                      alignContent: "center",
                      justifyContent: "space-between",
                      marginTop: "5rem",
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => handleClick()}
                      sx={{
                        backgroundColor: "#22345B",
                        textTransform: "capitalize",
                        fontFamily: "serif",
                        borderRadius: "8px",
                        ":hover": { backgroundColor: "#22345B" },
                      }}
                      disabled={previouscallingBackend}
                    >
                      {previouscallingBackend ? (
                        <CircularProgress
                          size={25}
                          sx={{ color: "#22345B", margin: "0 5px" }}
                        />
                      ) : (
                        "Previous"
                      )}
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => submit()}
                      sx={{
                        backgroundColor: "#22345B",
                        textTransform: "capitalize",
                        fontFamily: "serif",
                        borderRadius: "8px",
                        ":hover": { backgroundColor: "#22345B" },
                      }}
                      disabled={
                        !arr.every(
                          (item) =>
                            !Object.entries(item).some(
                              ([key, value]) => value === ""
                            )
                        ) ||
                        !isValid ||
                        callingBackend
                      }
                    >
                      {callingBackend ? (
                        <CircularProgress
                          size={25}
                          sx={{ color: "#22345B", margin: "0 5px" }}
                        />
                      ) : (
                        "Next"
                      )}
                    </Button>
                  </Grid>
                </Grid> */}
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
