import React, { useState, useEffect } from "react";
import { Reorder } from "framer-motion";
import { Grid, Button } from "@mui/material";
import axios from "../../libs/axios";
import { useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

export default function Rearrange({
  formData,
  handlePreviousPage,
  previouscallingBackend,
  setPreviousCallingBackend,
  handleNextPage,
  callingBackend,
  setCallingBackend,
}) {
  const [overallScore, setOverallScore] = useState(0);
  const [occupancyStar, setOccupancyStar] = useState(0);
  const [fire_d_p_Star, setFire_d_p_Star] = useState(0);
  const [managementStar, setManagementStar] = useState(0);
  const [specialStar, setSpecialStar] = useState(0);
  const [naturalStar, setNaturalStar] = useState(0);
  const allPriorities = [
    ...(!formData?.construction_priority?.c_check
      ? formData?.construction_priority?.priorityri_c
      : []),
    ...(!formData?.special_hazard_priority?.sh_check
      ? formData?.special_hazard_priority?.priorityri_sh
      : []),
    ...(!formData.fire_detection_protection_priority.fdp_check
      ? formData.fire_detection_protection_priority.priorityri_fdp
      : []),
    ...(!formData?.management_practice_priority?.mp_check
      ? formData?.management_practice_priority?.priorityri_mp
      : []),
    // ...(!formData.natural_hazards_exposure_priority.nhe_check
    //   ? formData.natural_hazards_exposure_priority.priorityri_nhe
    //   : []),
    ...(!formData.natural_hazards_recommendation_priority.nhr_check
      ? formData.natural_hazards_recommendation_priority.priorityri_nhr
      : []),
    ...formData?.new_ri?.ri,
  ];

  const uniquePriorities = allPriorities.filter(
    (item, index, self) =>
      index === self.findIndex((t) => t.title === item.title)
  );

  const [items, setItems] = useState(
    uniquePriorities?.filter((item) => item?.priority !== "Closed")
  );
  const colsedItems = formData?.priority?.priorityri?.filter(
    (item) => item?.priority === "Closed"
  );
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });
  const { state } = useLocation();
  const submit = async () => {
    const year = new Date().getFullYear();
    const changeItems = items.map((item, index) => {
      if (index + 1 < 10) {
        return {
          ...item,
          code: `${year} - 0${index + 1}`,
        };
      } else {
        return {
          ...item,
          code: `${year} - ${index + 1}`,
        };
      }
    });
    const changeClosedItems = colsedItems.map((item, index) => {
      if (index + items?.length + 1 < 10) {
        return {
          ...item,
          code: `${year} - 0${index + items?.length + 1}`,
        };
      } else {
        return {
          ...item,
          code: `${year} - ${index + items?.length + 1}`,
        };
      }
    });

    const overallScoreStarString =
      overallScore <= 1 ? `${overallScore} star` : `${overallScore} stars`;
    const occupancyStarString =
      occupancyStar <= 1 ? `${occupancyStar} star` : `${occupancyStar} stars`;
    const fire_d_p_StarString =
      fire_d_p_Star <= 1 ? `${fire_d_p_Star} star` : `${fire_d_p_Star} stars`;
    const managementStarString =
      managementStar <= 1
        ? `${managementStar} star`
        : `${managementStar} stars`;
    const specialStarString =
      specialStar <= 1 ? `${specialStar} star` : `${specialStar} stars`;
    const naturalStarStarString =
      naturalStar <= 1 ? `${naturalStar} star` : `${naturalStar} stars`;
    try {
      setCallingBackend(true);
      await axios.put(`/assesment/priority/${state.id}`, {
        priorityri: [...changeItems, ...changeClosedItems],
      });
      await axios.put(`/assesment/executive_summary/${state.id}`, {
        image: formData?.executive_summary?.image || "",
        heading1: formData?.executive_summary?.heading1 || "",
        heading2: formData?.executive_summary?.heading2 || "",
        heading3: formData?.executive_summary?.heading3 || "",
        heading4: formData?.executive_summary?.heading4 || "",
        heading5: formData?.executive_summary?.heading5 || "",
        heading6: formData?.executive_summary?.heading6 || "",
        occupancy_description:
          formData?.executive_summary?.occupancy_description || "",
        existing_risk: formData?.executive_summary?.existing_risk || "",
        significant_exposures:
          formData?.executive_summary?.significant_exposures || "",
        significant_changes_recent:
          formData?.executive_summary?.significant_changes_recent || "",
        significant_changes_future:
          formData?.executive_summary?.significant_changes_future || "",
        overallStar: overallScoreStarString,
        occupancyStar: occupancyStarString,
        fire_d_p_Star: fire_d_p_StarString,
        managementStar: managementStarString,
        specialStar: specialStarString,
        naturalStar: naturalStarStarString,
      });
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Priority page created successfully!",
      });
      setTimeout(
        () =>
          setAlert({
            showAlert: false,
            severity: "success",
            message: "Priority page created successfully!",
          }),
        handleNextPage(),
        5000
      );
    } catch (error) {
      if (error.response.data === "Priority page already exists") {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Priority page already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Priority page already exists!",
            }),
          5000
        );
      } else if (error.response.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Unauthorized Access!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Unauthorized Access!",
            }),
          5000
        );
      } else if (error.response.status === 500) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Server Error!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Server Error!",
            }),
          5000
        );
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Priority page already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Priority page already exists!",
            }),
          5000
        );
      }
    }
  };
  const handleClick = () => {
    setPreviousCallingBackend(true);
    handlePreviousPage();
  };

  // useEffect(() => {
  //   if (!formData?.priority?.priorityri?.length) {
  //     const allPriorities = [
  //       ...(!formData?.special_hazard_priority?.sh_check
  //         ? formData?.special_hazard_priority?.priorityri_sh
  //         : []),
  //       ...(!formData.fire_detection_protection_priority.fdp_check
  //         ? formData.fire_detection_protection_priority.priorityri_fdp
  //         : []),
  //       ...(!formData?.management_practice_priority?.mp_check
  //         ? formData?.management_practice_priority?.priorityri_mp
  //         : []),
  //       ...(!formData.natural_hazards_exposure_priority.nhe_check
  //         ? formData.natural_hazards_exposure_priority.priorityri_nhe
  //         : []),
  //       ...(!formData.natural_hazards_recommendation_priority.nhr_check
  //         ? formData.natural_hazards_recommendation_priority.priorityri_nhr
  //         : []),
  //       ...formData?.new_ri?.ri,
  //     ];

  //     const uniquePriorities = allPriorities.filter(
  //       (item, index, self) =>
  //         index === self.findIndex((t) => t.title === item.title)
  //     );

  //     setRiPriority(uniquePriorities);
  //   } else {
  //     setRiPriority(formData?.priority?.priorityri);
  //   }
  // }, [formData, selectRi]);

  const mflvalue = (mfl) => {
    if (mfl <= 50) {
      return 16;
    } else if (mfl > 50 && mfl <= 75) {
      return 14;
    } else {
      return 12;
    }
  };
  const SelectStar = (value) => {
    if (value <= 49) {
      return 1;
    } else if (value > 49 && value <= 64) {
      return 2;
    } else if (value > 64 && value <= 79) {
      return 3;
    } else if (value > 79 && value <= 89) {
      return 4;
    } else {
      return 5;
    }
  };

  // Define data here
  const data = {
    labels: [
      "Inherent Occupancy Risk",
      "Fire Detection & Protection",
      "Management Practices",
      "Special Hazards",
      "Natural Hazards",
    ],
    datasets: [
      {
        label: "Rating",
        data: [
          SelectStar(
            (Math.round(
              formData?.star_rating_page1?.construction_category?.value +
                mflvalue(formData?.mfl_calculation?.mfl_estimate_precentage) +
                formData?.star_rating_page1?.predominant_construction?.value
            ) /
              80) *
              100
          ),
          SelectStar(
            Math.round(
              (formData?.fire_detection_protection?.available_score / 30) * 100
            )
          ),
          SelectStar(
            Math.round(
              (formData?.management_practice?.available_score / 30) * 100
            )
          ),
          SelectStar(
            Math.round((formData?.special_hazard?.available_score / 20) * 100)
          ),
          SelectStar(
            Math.round(
              (formData?.natural_hazards_exposure?.available_score / 40) * 100 +
                (formData?.natural_hazards_recommendation?.available_score /
                  40) *
                  100
            )
          ),
        ],
        backgroundColor: "rgba(166, 133, 0)",
      },
    ],
  };

  // Define scores here
  const roof_predominant_construction = Math.round(
    (formData?.star_rating_page1?.predominant_construction.value / 80) * 100
  );
  const naturalHazardsExposureScore = Math.round(
    (formData?.natural_hazards_exposure?.available_score / 40) * 100
  );
  const naturalHazardsRecommendationScore = Math.round(
    (formData?.natural_hazards_recommendation?.available_score / 40) * 100
  );
  const specialHazardScore = Math.round(
    (formData?.special_hazard?.available_score / 20) * 100
  );
  const managementPracticeScore = Math.round(
    (formData?.management_practice?.available_score / 30) * 100
  );
  const fireDetectionProtectionScore = Math.round(
    (formData?.fire_detection_protection?.available_score / 30) * 100
  );
  const star1construction_category = Math.round(
    (formData?.star_rating_page1?.construction_category?.value / 80) * 100
  );

  const total_available_s =
    formData?.star_rating_page1?.construction_category?.value +
    mflvalue(formData?.mfl_calculation?.mfl_estimate_precentage) +
    formData?.star_rating_page1?.predominant_construction?.value +
    formData?.natural_hazards_exposure?.available_score;

  const current_score =
    ((formData?.fire_detection_protection?.available_score +
      formData?.management_practice?.available_score +
      formData?.special_hazard?.available_score +
      formData?.natural_hazards_recommendation?.available_score) /
      100) *
    total_available_s;

  useEffect(() => {
    setOverallScore(SelectStar((current_score / total_available_s) * 100));
    setOccupancyStar(
      SelectStar(star1construction_category + roof_predominant_construction)
    );
    setFire_d_p_Star(SelectStar(fireDetectionProtectionScore));
    setManagementStar(SelectStar(managementPracticeScore));
    setSpecialStar(SelectStar(specialHazardScore));
    setNaturalStar(
      SelectStar(
        naturalHazardsExposureScore + naturalHazardsRecommendationScore
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    current_score,
    total_available_s,
    setOverallScore,
    setOccupancyStar,
    setFire_d_p_Star,
    setManagementStar,
    setSpecialStar,
    setNaturalStar,
  ]);
  return (
    <div>
      <Grid
        container
        xs={12}
        sx={{
          padding: "1rem",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p className="formName">Risk Improvement Order</p>
        <Grid container xs={10} spacing={1}>
          <Grid container item xs={12} spacing={1}>
            <Grid className="inputTextGrid" item xs={8}>
              <p
                className="inputTextH"
                style={{ listStyleType: "none", margin: 0 }}
              >
                Title
              </p>
            </Grid>
            <Grid className="inputTextGrid" item xs={4}>
              <p
                className="inputTextH"
                style={{ listStyleType: "none", margin: 0 }}
              >
                Priority
              </p>
            </Grid>
          </Grid>
          <Reorder.Group
            axis="y"
            values={items}
            onReorder={setItems}
            style={{ width: "100%", listStyleType: "none" }}
          >
            {items.map((item, index) => (
              <Reorder.Item
                key={item.title}
                value={item}
                style={{
                  padding: "0.5rem",
                  margin: "0.5rem 0",
                  border: "1px solid #eee",
                  borderRadius: "5px",
                  cursor: "pointer",
                  listStyleType: "none",
                }}
              >
                <Grid
                  className="inputTextGrid"
                  container
                  alignItems="center"
                  item
                  xs={12}
                >
                  <Grid item xs={1}>
                    {index > 0 && (
                      <ArrowUpwardIcon sx={{ cursor: "pointer" }} />
                    )}
                    {index < items.length - 1 && (
                      <ArrowDownwardIcon sx={{ cursor: "pointer" }} />
                    )}
                  </Grid>
                  <Grid item xs={7} spacing={1}>
                    <p
                      className="inputTextP"
                      style={{ listStyleType: "none", margin: 0 }}
                    >
                      {item?.title}
                    </p>
                  </Grid>
                  <Grid item xs={4} spacing={1} style={{ paddingLeft: "1rem" }}>
                    <p
                      className="inputTextP"
                      style={{ listStyleType: "none", margin: 0 }}
                    >
                      {item?.priority}
                    </p>
                  </Grid>
                </Grid>
              </Reorder.Item>
            ))}
          </Reorder.Group>
          <Grid
            container
            item
            xs={12}
            sx={{
              alignContent: "center",
              justifyContent: "space-between",
              marginTop: "2rem",
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#22345B",
                textTransform: "capitalize",
                fontFamily: "serif",
                borderRadius: "8px",
                ":hover": { backgroundColor: "#22345B" },
              }}
              onClick={() => handleClick()}
              disabled={previouscallingBackend}
            >
              {previouscallingBackend ? (
                <CircularProgress
                  size={25}
                  sx={{ color: "#22345B", margin: "0 5px" }}
                />
              ) : (
                "Previous"
              )}
            </Button>
            <Button
              variant="contained"
              onClick={() => submit()}
              sx={{
                backgroundColor: "#22345B",
                textTransform: "capitalize",
                fontFamily: "serif",
                borderRadius: "8px",
                ":hover": { backgroundColor: "#22345B" },
              }}
              disabled={callingBackend}
            >
              {callingBackend ? (
                <CircularProgress
                  size={25}
                  sx={{ color: "#22345B", margin: "0 5px" }}
                />
              ) : (
                "Next"
              )}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
