import React, { useState } from "react";
import axios from "../../libs/axios";
import { Formik, Form } from "formik";
import TextField from "@mui/material/TextField";
import InputFeild from "../../components/common/NewTextFeild";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import "../Assesment/assesment.css";
import { Button } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default function StarRateTwo({
  formData,
  handlePreviousPage,
  previouscallingBackend,
  setPreviousCallingBackend,
  handleNextPage,
  callingBackend,
  setCallingBackend,
}) {
  const typeOptions = [
    { name: "Adequate", value: 1 },
    { name: "Improvement Needed", value: 0.9 },
    {
      name: "Inadequate",
      value: 0.8,
    },
    { name: "N/A", value: 1 },
  ];
  const typeOptiontwo = [
    { name: "Adequate", value: 1 },
    { name: "Improvement Needed", value: 0.95 },
    {
      name: "Inadequate",
      value: 0.9,
    },
    { name: "N/A", value: 1 },
  ];
  const hazard = [
    {
      id: 0,
      name: "Are ignitable liquids in operation (eg. hydraulic oils, heat transfer fluids) handled appropriately (detection, interlocks, shutdowns, alarms etc.)?",
      pdfname: "Ignitable Liquids in Operation",
      type: { name: "", value: 1 },
      ri: 10,
      description: "",
      rioption: {
        title: "",
        details: "",
        theRisk: "",
      },
    },
    {
      id: 1,
      name: "Are all ignitable liquids stored adequately designed (bunding, drainage, ventilation, etc)?",
      pdfname: "Ignitable Liquids in Storage",
      type: { name: "", value: 1 },
      ri: 11,
      description: "",
      rioption: {
        title: "",
        details: "",
        theRisk: "",
      },
    },
    {
      id: 2,
      name: "Is adequate separation provided between specialised electrical equipment (forklift charging stations, heaters etc.) and combustibles?",
      pdfname: "Separation of Ignition Sources and Ignitables",
      type: { name: "", value: 1 },
      ri: 12,
      description: "",
      rioption: {
        title: "",
        details: "",
        theRisk: "",
      },
    },
    {
      id: 3,
      name: "Are all Liquid Insulated Transformers adequaltely separated from each other and buildings or appropriate fire-rated barriers provided?",
      pdfname: "Transformer Separation",
      type: { name: "", value: 1 },
      ri: 23,
      description: "",
      rioption: {
        title: "",
        details: "",
        theRisk: "",
      },
    },
    {
      id: 4,
      name: "Is dissolved gas analysis conducted as part of annual maintenance on all Liquid Insulated Transformers?",
      pdfname: "Transformer Maintenance",
      type: { name: "", value: 1 },
      ri: 13,
      description: "",
      rioption: {
        title: "",
        details: "",
        theRisk: "",
      },
    },
    {
      id: 5,
      name: "Are all boilers serviced annually, including testing of each component of a the gas fuel train?",
      pdfname: "Boiler Servicing",
      type: { name: "", value: 1 },
      ri: 24,
      description: "",
      rioption: {
        title: "",
        details: "",
        theRisk: "",
      },
    },
    {
      id: 6,
      name: "Are all idle pallets and ignitable yard storage  stored at least 5m away from buildings or adequately protected?",
      pdfname: "Idle Pallets",
      type: { name: "", value: 1 },
      ri: 14,
      description: "",
      rioption: {
        title: "",
        details: "",
        theRisk: "",
      },
    },
    {
      id: 7,
      name: "Are all generators tested monthly/ annually serviced/ adequately bunded with interlocks and shutdowns?",
      pdfname: "Generators",
      type: { name: "", value: 1 },
      ri: 15,
      description: "",
      rioption: {
        title: "",
        details: "",
        theRisk: "",
      },
    },
    {
      id: 8,
      name: "",
      pdfname: "",
      type: { name: "", value: 1 },
      ri: 0,
      description: "",
      rioption: {
        title: "",
        details: "",
        theRisk: "",
      },
    },
    {
      id: 9,
      name: "",
      pdfname: "",
      type: { name: "", value: 1 },
      ri: 0,
      description: "",
      rioption: {
        title: "",
        details: "",
        theRisk: "",
      },
    },
    {
      id: 10,
      name: "",
      pdfname: "",
      type: { name: "", value: 1 },
      ri: 0,
      description: "",
      rioption: {
        title: "",
        details: "",
        theRisk: "",
      },
    },
    {
      id: 11,
      name: "",
      pdfname: "",
      type: { name: "", value: 1 },
      ri: 0,
      description: "",
      rioption: {
        title: "",
        details: "",
        theRisk: "",
      },
    },
  ];
  const toolbarOptions = [
    ["bold", "italic", "underline"],
    [{ header: 1 }, { header: 2 }],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }],
  ];
     const module = {
       toolbar: toolbarOptions,
  };
  
  const { state } = useLocation();
  const [arr, setArr] = useState(formData?.special_hazard?.hazard.length ? formData?.special_hazard?.hazard : hazard);
  const changeFormData = (value, name , id) => {
    const objIndex = arr?.findIndex((obj) => obj?.id === id);

    arr[objIndex][name] = value;

    setArr([...arr]);
  };
   const changeAutocompleteFormData = (value, name, id) => {
     const objIndex = arr?.findIndex((obj) => obj?.id === id);

     arr[objIndex][name] = value || { name: "", value: 1 };

     setArr([...arr]);
   };

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const submit = async (e) => {
    setCallingBackend(true);
    const availableScore = arr?.reduce(
      (n, { type }) => n * Number(type?.value),
      1
    );
    const updatedArray = arr.map((item) => {
      if (item.id >= 8) {
        return {
          ...item,
          pdfname: item.name,
        };
      }
      return item; 
    });
   
    console.log("type", updatedArray);
    try {
      await axios.put(`/assesment/special_hazard/${state.id}`, {
        hazard: updatedArray,
        available_score: Math.round(availableScore * 20),
      });
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Star rate page one created successfully!",
      });
      setTimeout(
        () =>
          setAlert({
            showAlert: false,
            severity: "success",
            message: "Star rate page one created successfully!",
          }),
        handleNextPage(),
        5000
      );
    } catch (error) {
      if (error.response.data === "Star rate page one already exists") {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Star rate page one already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Star rate page one already exists!",
            }),
          5000
        );
      } else if (error.response.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Unauthorized Access!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Unauthorized Access!",
            }),
          5000
        );
      } else if (error.response.status === 500) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Server Error!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Server Error!",
            }),
          5000
        );
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Star rate page one already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Star rate page one already exists!",
            }),
          5000
        );
      }
    }
  };
  const handleClick = () => {
    setPreviousCallingBackend(true);
    handlePreviousPage();
  };

  return (
    <div>
      <Formik
        initialValues={hazard}
        onSubmit={submit}
        // validationSchema={validationSchema}
        enableReinitialize
      >
        {({ dirty, isValid }) => {
          return (
            <Form>
              <Grid
                container
                xs={12}
                sx={{
                  padding: "1rem",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="formName">PRS AREA - Special Hazards</p>
                <Grid container xs={10} spacing={1}>
                  {arr.map((item, i) => {
                    return (
                      <>
                        <Grid item xs={12} sx={{ marginTop: "1.5rem" }}>
                          {item?.id === 8 || item?.id === 9 ? (
                            <InputFeild
                              name="name"
                              value={item?.name}
                              Placeholder={
                                "…other Major special hazard exposure"
                              }
                              onChange={(e) =>
                                changeFormData(
                                  e?.target?.value,
                                  "name",
                                  item?.id
                                )
                              }
                              component={TextField}
                              variant
                              fullWidth
                              bgColor="#FFFFFF"
                              margin="dense"
                            />
                          ) : item?.id === 10 || item?.id === 11 ? (
                            <InputFeild
                              name="name"
                              value={item?.name}
                              Placeholder={
                                "…other Minor special hazard exposure"
                              }
                              onChange={(e) =>
                                changeFormData(
                                  e?.target?.value,
                                  "name",
                                  item?.id
                                )
                              }
                              component={TextField}
                              variant
                              fullWidth
                              bgColor="#FFFFFF"
                              margin="dense"
                            />
                          ) : (
                            <p className="font">{item?.name}</p>
                          )}
                          {item?.id === 2 ||
                          item?.id === 3 ||
                          item?.id === 4 ||
                          item?.id === 6 ||
                          item?.id === 10 ||
                          item?.id === 11 ? (
                            <Autocomplete
                              hiddenLabel
                              id="type"
                              name="type"
                              size="small"
                              sx={{ marginBottom: "5px" }}
                              value={item?.type}
                              options={typeOptiontwo}
                              getOptionLabel={(option) => option?.name}
                              onChange={(event, newValue) => {
                                console.log("newValue", newValue);
                                changeAutocompleteFormData(
                                  newValue,
                                  "type",
                                  item?.id
                                );
                              }}
                              renderInput={(params) => (
                                <TextField size="small" {...params} />
                              )}
                            />
                          ) : (
                            <Autocomplete
                              hiddenLabel
                              id="type"
                              name="type"
                              size="small"
                              sx={{ marginBottom: "5px" }}
                              value={item?.type}
                              options={typeOptions}
                              getOptionLabel={(option) => option?.name}
                              onChange={(event, newValue) => {
                                console.log("newValue", newValue);
                                changeAutocompleteFormData(
                                  newValue,
                                  "type",
                                  item?.id
                                );
                              }}
                              renderInput={(params) => (
                                <TextField size="small" {...params} />
                              )}
                            />
                          )}
                          <Grid item xs={12} sx={{ marginBottom: "50px" }}>
                            <InputFeild
                              name="description"
                              onChange={(e) =>
                                changeFormData( e.target.value, "description", item?.id)
                              }
                              value={item?.description || ""}
                              component={TextField}
                              variant
                              fullWidth
                              bgColor="#FFFFFF"
                              margin="dense"
                              multiline
                              rows={3}
                            />
                          </Grid>
                        </Grid>
                      </>
                    );
                  })}
                  <Grid
                    container
                    item
                    xs={12}
                    sx={{
                      alignContent: "center",
                      justifyContent: "space-between",
                      marginTop: "2rem",
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => handleClick()}
                      sx={{
                        backgroundColor: "#22345B",
                        textTransform: "capitalize",
                        fontFamily: "serif",
                        borderRadius: "8px",
                        ":hover": { backgroundColor: "#22345B" },
                      }}
                      disabled={previouscallingBackend}
                    >
                      {previouscallingBackend ? (
                        <CircularProgress
                          size={25}
                          sx={{ color: "#22345B", margin: "0 5px" }}
                        />
                      ) : (
                        "Previous"
                      )}
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#22345B",
                        textTransform: "capitalize",
                        fontFamily: "serif",
                        borderRadius: "8px",
                        ":hover": { backgroundColor: "#22345B" },
                      }}
                      type="submit"
                      disabled={
                        callingBackend ||
                        !arr?.every(
                          (item) =>
                            !Object?.entries(item)?.some(
                              ([key, value]) =>
                                item.id <= 7 &&
                                (item.type.name === "" ||
                                  item.type.name === null)
                            )
                        )
                      }
                    >
                      {callingBackend ? (
                        <CircularProgress
                          size={25}
                          sx={{ color: "#22345B", margin: "0 5px" }}
                        />
                      ) : (
                        "Next"
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
