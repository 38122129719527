import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Grid } from "@mui/material";
import axios from "../../libs/axios";
import { useLocation } from "react-router-dom";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS?.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels // Registering the DataLabels plugin
);

export default function PumpsetChart({ text }) {
  const { state } = useLocation();
  const [waterSupply, setWaterSupply] = useState([{}]);
  const [pump1, setPump1] = useState([{}]);
  const [pump2, setPump2] = useState([{}]);
  const [pump3, setPump3] = useState([{}]);
  const [pump4, setPump4] = useState([{}]);
  const [activeDataset, setActiveDataset] = useState(null);

  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          title: () => "",
          label: (context) => 
            `Name: ${context?.raw?.name}, Value: (x:${context?.parsed?.x}, y:${context?.parsed?.y})`,
        },
      },
      legend: {
        position: "top",
        align: "start",
        justifyContent: "center",
        labels: {
          padding: 20,
          usePointStyle: true,
          pointStyle: "circle",
          boxWidth: 10,
          textAlign: "start", 
          font: {
            size: 12, // Adjust text size
          },
        },
        onClick: (e, legendItem) => {
        const datasetLabel = legendItem?.text;
          setActiveDataset((prevDataset) =>
            prevDataset === datasetLabel ? null : datasetLabel
          );
        },
      },
      datalabels: {
        display: true,
        align: "top",
      formatter: (value, context) => value?.name,
        color: "black",
      },
    },
    scales: {
      x: {
        type: "linear", // X-axis is linear
        position: "bottom", // Positioned at the bottom
        title: {
          display: true,
          text: "Demand Flow (L/min)", // Title of the X-axis
        },
      },
      y: {
        title: {
          display: true,
          text: "Pressure (kPa)", // Title of the Y-axis
        },
      },
    },
  };

  const getDataSets = () => [
    {
      label: "Pump 1",
      data: pump1?.map((item, index) => ({
        x: item?.sprinkler_pump_flow,
        y: item?.discharge,
        name: `Point ${index + 1}`,
      })),
      datalabels: {
        display: false,
      },
      fill: false,
      borderColor: "#A7C0E4",
      backgroundColor: "#A7C0E4",
      tension: 0.1,
      hidden: activeDataset !== null && activeDataset !== "Pump 1",
    },
    {
      label: "Pump 2",
      data: pump2?.map((item, index) => ({
        x: item?.sprinkler_pump_flow,
        y: item?.discharge,
        name: `Point ${index + 1}`,
      })),
      fill: false,
      borderColor: "#3A588D",
      backgroundColor: "#3A588D",
      tension: 0.1,
      hidden: activeDataset !== null && activeDataset !== "Pump 2",
      datalabels: {
        display: false,
      },
    },
    {
      label: "Pump 3",
      data: pump3?.map((item, index) => ({
        x: item?.sprinkler_pump_flow,
        y: item?.discharge,
        name: `Point ${index + 1}`,
      })),
      datalabels: {
        display: false,
      },
      fill: false,
      borderColor: "#6D81A0",
      backgroundColor: "#6D81A0",
      tension: 0.1,
      hidden: activeDataset !== null && activeDataset !== "Pump 3",
    },
    {
      label: "Pump 4",
      data: pump4?.map((item, index) => ({
        x: item?.sprinkler_pump_flow,
        y: item?.discharge,
        name: `Point ${index + 1}`,
      })),
      datalabels: {
        display: false,
      },
      fill: false,
      borderColor: "#5D84BF",
      backgroundColor: "#5D84BF",
      tension: 0.1,
      hidden: activeDataset !== null && activeDataset !== "Pump 4",
    },
    {
      label: waterSupply[0]?.sprinkler_system
        ? waterSupply[0]?.sprinkler_system
        : "",
      data: [
        { x: waterSupply[0]?.demand_flow, y: waterSupply[0]?.demand_pressure },
      ],
      fill: false,
      borderColor: waterSupply[0]?.sprinkler_system ? ["#FCADFF"] : ["#ffffff"],
      backgroundColor: waterSupply[0]?.sprinkler_system
        ? ["#FCADFF"]
        : ["#ffffff"],
      tension: 0.4,
      showLine: false,
      borderWidth: 1,
      hidden:
        activeDataset !== null &&
        activeDataset !== waterSupply[0]?.sprinkler_system,
      datalabels: {
        display: text,
        align: "bottom",
        formatter: (value, context) => {
          const name = value?.name;
          const xValue =
            context?.chart?.data?.datasets[context?.datasetIndex]?.data[
              context?.dataIndex
            ]?.x;
          const yValue =
            context?.chart?.data?.datasets[context?.datasetIndex]?.data[
              context?.dataIndex
            ]?.y;

          return `${xValue}L/min @ ${yValue}kPa`;
        },
      },
    },
    {
      label: waterSupply[1]?.sprinkler_system
        ? waterSupply[1]?.sprinkler_system
        : "",
      data: [
        { x: waterSupply[1]?.demand_flow, y: waterSupply[1]?.demand_pressure },
      ],
      fill: false,
      borderColor: waterSupply[1]?.sprinkler_system ? ["#D176D6"] : ["#ffffff"],
      backgroundColor: waterSupply[1]?.sprinkler_system
        ? ["#D176D6"]
        : ["#ffffff"],
      tension: 0.4,
      showLine: false,
      borderWidth: 1,
      hidden:
        activeDataset !== null &&
        activeDataset !== waterSupply[1]?.sprinkler_system,
      datalabels: {
        display: text,
        align: "bottom",
        formatter: (value, context) => {
          const name = value?.name;
          const xValue =
            context?.chart?.data?.datasets[context?.datasetIndex]?.data[
              context?.dataIndex
            ]?.x;
          const yValue =
            context?.chart?.data?.datasets[context?.datasetIndex]?.data[
              context?.dataIndex
            ]?.y;

          return `${xValue}L/min @ ${yValue}kPa`;
        },
      },
    },
    {
      label: waterSupply[2]?.sprinkler_system
        ? waterSupply[2]?.sprinkler_system
        : "",
      data: [
        { x: waterSupply[2]?.demand_flow, y: waterSupply[2]?.demand_pressure },
      ],
      fill: false,
      borderColor: waterSupply[2]?.sprinkler_system ? ["#BD50C3"] : ["#ffffff"],
      backgroundColor: waterSupply[2]?.sprinkler_system
        ? ["#BD50C3"]
        : ["#ffffff"],
      tension: 0.4,
      showLine: false,
      borderWidth: 1,
      hidden:
        activeDataset !== null &&
        activeDataset !== waterSupply[2]?.sprinkler_system,
      datalabels: {
        display: text,
        align: "bottom",
        formatter: (value, context) => {
          const name = value?.name;
          const xValue =
            context?.chart?.data?.datasets[context?.datasetIndex]?.data[
              context?.dataIndex
            ]?.x;
          const yValue =
            context?.chart?.data?.datasets[context?.datasetIndex]?.data[
              context?.dataIndex
            ]?.y;

          return `${xValue}L/min @ ${yValue}kPa`;
        },
      },
    },
    {
      label: waterSupply[3]?.sprinkler_system
        ? waterSupply[3]?.sprinkler_system
        : "",
      data: [
        { x: waterSupply[3]?.demand_flow, y: waterSupply[3]?.demand_pressure },
      ],
      fill: false,
      borderColor: waterSupply[3]?.sprinkler_system ? ["#9C2DA6"] : ["#ffffff"],
      backgroundColor: waterSupply[3]?.sprinkler_system
        ? ["#9C2DA6"]
        : ["#ffffff"],
      tension: 0.4,
      showLine: false,
      borderWidth: 1,
      hidden:
        activeDataset !== null &&
        activeDataset !== waterSupply[3]?.sprinkler_system,
      datalabels: {
        display: text,
        align: "bottom",
        formatter: (value, context) => {
          const name = value?.name;
          const xValue =
            context?.chart?.data?.datasets[context?.datasetIndex]?.data[
              context?.dataIndex
            ]?.x;
          const yValue =
            context?.chart?.data?.datasets[context?.datasetIndex]?.data[
              context?.dataIndex
            ]?.y;

          return `${xValue}L/min @ ${yValue}kPa`;
        },
      },
    },
    {
      label: waterSupply[4]?.sprinkler_system
        ? waterSupply[4]?.sprinkler_system
        : "",
      data: [
        { x: waterSupply[4]?.demand_flow, y: waterSupply[4]?.demand_pressure },
      ],
      fill: false,
      borderColor: waterSupply[4]?.sprinkler_system ? ["#840A90"] : ["#ffffff"],
      backgroundColor: waterSupply[4]?.sprinkler_system
        ? ["#840A90"]
        : ["#ffffff"],
      tension: 0.4,
      showLine: false,
      borderWidth: 1,
      hidden:
        activeDataset !== null &&
        activeDataset !== waterSupply[4]?.sprinkler_system,
      datalabels: {
        display: text,
        align: "bottom",
        formatter: (value, context) => {
          const name = value?.name;
          const xValue =
            context?.chart?.data?.datasets[context?.datasetIndex]?.data[
              context?.dataIndex
            ]?.x;
          const yValue =
            context?.chart?.data?.datasets[context?.datasetIndex]?.data[
              context?.dataIndex
            ]?.y;

          return `${xValue}L/min @ ${yValue}kPa`;
        },
      },
    },
    {
      label: waterSupply[5]?.sprinkler_system
        ? waterSupply[5]?.sprinkler_system
        : "",
      data: [
        { x: waterSupply[5]?.demand_flow, y: waterSupply[5]?.demand_pressure },
      ],
      fill: false,
      borderColor: waterSupply[5]?.sprinkler_system ? ["#DBD8D4"] : ["#ffffff"],
      backgroundColor: waterSupply[5]?.sprinkler_system
        ? ["#DBD8D4"]
        : ["#ffffff"],
      tension: 0.4,
      showLine: false,
      borderWidth: 1,
      hidden:
        activeDataset !== null &&
        activeDataset !== waterSupply[5]?.sprinkler_system,
      datalabels: {
        display: text,
        align: "bottom",
        formatter: (value, context) => {
          const name = value?.name;
          const xValue =
            context?.chart?.data?.datasets[context?.datasetIndex]?.data[
              context?.dataIndex
            ]?.x;
          const yValue =
            context?.chart?.data?.datasets[context?.datasetIndex]?.data[
              context?.dataIndex
            ]?.y;

          return `${xValue}L/min @ ${yValue}kPa`;
        },
      },
    },
    {
      label: waterSupply[6]?.sprinkler_system
        ? waterSupply[6]?.sprinkler_system
        : "",
      data: [
        { x: waterSupply[6]?.demand_flow, y: waterSupply[6]?.demand_pressure },
      ],
      fill: false,
      borderColor: waterSupply[6]?.sprinkler_system ? ["#6F6B6A"] : ["#ffffff"],
      backgroundColor: waterSupply[6]?.sprinkler_system
        ? ["#6F6B6A"]
        : ["#ffffff"],
      tension: 0.4,
      showLine: false,
      borderWidth: 1,
      hidden:
        activeDataset !== null &&
        activeDataset !== waterSupply[6]?.sprinkler_system,
      datalabels: {
        display: text,
        align: "bottom",
        formatter: (value, context) => {
          const name = value?.name;
          const xValue =
            context?.chart?.data?.datasets[context?.datasetIndex]?.data[
              context?.dataIndex
            ]?.x;
          const yValue =
            context?.chart?.data?.datasets[context?.datasetIndex]?.data[
              context?.dataIndex
            ]?.y;

          return `${xValue}L/min @ ${yValue}kPa`;
        },
      },
    },
    {
      label: waterSupply[7]?.sprinkler_system
        ? waterSupply[7]?.sprinkler_system
        : "",
      data: [
        { x: waterSupply[7]?.demand_flow, y: waterSupply[7]?.demand_pressure },
      ],
      fill: false,
      borderColor: waterSupply[7]?.sprinkler_system ? ["#8D8787"] : ["#ffffff"],
      backgroundColor: waterSupply[7]?.sprinkler_system
        ? ["#8D8787"]
        : ["#ffffff"],
      tension: 0.4,
      showLine: false,
      borderWidth: 1,
      hidden:
        activeDataset !== null &&
        activeDataset !== waterSupply[7]?.sprinkler_system,
      datalabels: {
        display: text,
        align: "bottom",
        formatter: (value, context) => {
          const name = value?.name;
          const xValue =
            context?.chart?.data?.datasets[context?.datasetIndex]?.data[
              context?.dataIndex
            ]?.x;
          const yValue =
            context?.chart?.data?.datasets[context?.datasetIndex]?.data[
              context?.dataIndex
            ]?.y;

          return `${xValue}L/min @ ${yValue}kPa`;
        },
      },
    },
    {
      label: waterSupply[8]?.sprinkler_system
        ? waterSupply[8]?.sprinkler_system
        : "",
      data: [
        { x: waterSupply[8]?.demand_flow, y: waterSupply[8]?.demand_pressure },
      ],
      fill: false,
      borderColor: waterSupply[8]?.sprinkler_system ? ["#ACA6A6"] : ["#ffffff"],
      backgroundColor: waterSupply[8]?.sprinkler_system
        ? ["#ACA6A6"]
        : ["#ffffff"],
      tension: 0.4,
      showLine: false,
      borderWidth: 1,
      hidden:
        activeDataset !== null &&
        activeDataset !== waterSupply[8]?.sprinkler_system,
      datalabels: {
        display: text,
        align: "bottom",
        formatter: (value, context) => {
          const name = value?.name;
          const xValue =
            context?.chart?.data?.datasets[context?.datasetIndex]?.data[
              context?.dataIndex
            ]?.x;
          const yValue =
            context?.chart?.data?.datasets[context?.datasetIndex]?.data[
              context?.dataIndex
            ]?.y;

          return `${xValue}L/min @ ${yValue}kPa`;
        },
      },
    },
    {
      label: waterSupply[9]?.sprinkler_system
        ? waterSupply[9]?.sprinkler_system
        : "",
      data: [
        { x: waterSupply[9]?.demand_flow, y: waterSupply[9]?.demand_pressure },
      ],
      fill: false,
      borderColor: waterSupply[9]?.sprinkler_system ? ["#CCC8C7"] : ["#ffffff"],
      backgroundColor: waterSupply[9]?.sprinkler_system
        ? ["#CCC8C7"]
        : ["#ffffff"],
      tension: 0.4,
      showLine: false,
      borderWidth: 1,
      hidden:
        activeDataset !== null &&
        activeDataset !== waterSupply[9]?.sprinkler_system,
      datalabels: {
        display: text,
        align: "bottom",
        formatter: (value, context) => {
          const name = value?.name;
          const xValue =
            context?.chart?.data?.datasets[context?.datasetIndex]?.data[
              context?.dataIndex
            ]?.x;
          const yValue =
            context?.chart?.data?.datasets[context?.datasetIndex]?.data[
              context?.dataIndex
            ]?.y;

          return `${xValue}L/min @ ${yValue}kPa`;
        },
      },
    },
    {
      label: waterSupply[10]?.sprinkler_system
        ? waterSupply[10]?.sprinkler_system
        : "",
      data: [
        {
          x: waterSupply[10]?.demand_flow,
          y: waterSupply[10]?.demand_pressure,
        },
      ],
      fill: false,
      borderColor: waterSupply[10]?.sprinkler_system
        ? ["#F6E593"]
        : ["#ffffff"],
      backgroundColor: waterSupply[10]?.sprinkler_system
        ? ["#F6E593"]
        : ["#ffffff"],
      tension: 0.4,
      showLine: false,
      borderWidth: 1,
      hidden:
        activeDataset !== null &&
        activeDataset !== waterSupply[10]?.sprinkler_system,
      datalabels: {
        display: text,
        align: "bottom",
        formatter: (value, context) => {
          const name = value?.name;
          const xValue =
            context?.chart?.data?.datasets[context?.datasetIndex]?.data[
              context?.dataIndex
            ]?.x;
          const yValue =
            context?.chart?.data?.datasets[context?.datasetIndex]?.data[
              context?.dataIndex
            ]?.y;

          return `${xValue}L/min @ ${yValue}kPa`;
        },
      },
    },
    {
      label: waterSupply[11]?.sprinkler_system
        ? waterSupply[11]?.sprinkler_system
        : "",
      data: [
        {
          x: waterSupply[11]?.demand_flow,
          y: waterSupply[11]?.demand_pressure,
        },
      ],
      fill: false,
      borderColor: waterSupply[11]?.sprinkler_system
        ? ["#DFCB6C"]
        : ["#ffffff"],
      backgroundColor: waterSupply[11]?.sprinkler_system
        ? ["#DFCB6C"]
        : ["#ffffff"],
      tension: 0.4,
      showLine: false,
      borderWidth: 1,
      hidden:
        activeDataset !== null &&
        activeDataset !== waterSupply[11]?.sprinkler_system,
      datalabels: {
        display: text,
        align: "bottom",
        formatter: (value, context) => {
          const name = value?.name;
          const xValue =
            context?.chart?.data?.datasets[context?.datasetIndex]?.data[
              context?.dataIndex
            ]?.x;
          const yValue =
            context?.chart?.data?.datasets[context?.datasetIndex]?.data[
              context?.dataIndex
            ]?.y;

          return `${xValue}L/min @ ${yValue}kPa`;
        },
      },
    },
    {
      label: waterSupply[12]?.sprinkler_system
        ? waterSupply[12]?.sprinkler_system
        : "",
      data: [
        {
          x: waterSupply[12]?.demand_flow,
          y: waterSupply[12]?.demand_pressure,
        },
      ],
      fill: false,
      borderColor: waterSupply[12]?.sprinkler_system
        ? ["#BFAA41"]
        : ["#ffffff"],
      backgroundColor: waterSupply[12]?.sprinkler_system
        ? ["#BFAA41"]
        : ["#ffffff"],
      tension: 0.4,
      showLine: false,
      borderWidth: 1,
      hidden:
        activeDataset !== null &&
        activeDataset !== waterSupply[12]?.sprinkler_system,
      datalabels: {
        display: text,
        align: "bottom",
        formatter: (value, context) => {
          const name = value?.name;
          const xValue =
            context?.chart?.data?.datasets[context?.datasetIndex]?.data[
              context?.dataIndex
            ]?.x;
          const yValue =
            context?.chart?.data?.datasets[context?.datasetIndex]?.data[
              context?.dataIndex
            ]?.y;

          return `${xValue}L/min @ ${yValue}kPa`;
        },
      },
    },
    {
      label: waterSupply[13]?.sprinkler_system
        ? waterSupply[13]?.sprinkler_system
        : "",
      data: [
        {
          x: waterSupply[13]?.demand_flow,
          y: waterSupply[13]?.demand_pressure,
        },
      ],
      fill: false,
      borderColor: waterSupply[13]?.sprinkler_system
        ? ["#746402"]
        : ["#ffffff"],
      backgroundColor: waterSupply[13]?.sprinkler_system
        ? ["#746402"]
        : ["#ffffff"],
      tension: 0.4,
      showLine: false,
      borderWidth: 1,
      hidden:
        activeDataset !== null &&
        activeDataset !== waterSupply[13]?.sprinkler_system,
      datalabels: {
        display: text,
        align: "bottom",
        formatter: (value, context) => {
          const name = value?.name;
          const xValue =
            context?.chart?.data?.datasets[context?.datasetIndex]?.data[
              context?.dataIndex
            ]?.x;
          const yValue =
            context?.chart?.data?.datasets[context?.datasetIndex]?.data[
              context?.dataIndex
            ]?.y;

          return `${xValue}L/min @ ${yValue}kPa`;
        },
      },
    },
    {
      label: waterSupply[14]?.sprinkler_system
        ? waterSupply[14]?.sprinkler_system
        : "",
      data: [
        {
          x: waterSupply[14]?.demand_flow,
          y: waterSupply[14]?.demand_pressure,
        },
      ],
      fill: false,
      borderColor: waterSupply[14]?.sprinkler_system
        ? ["#9E8912"]
        : ["#ffffff"],
      backgroundColor: waterSupply[14]?.sprinkler_system
        ? ["#9E8912"]
        : ["#ffffff"],
      tension: 0.4,
      showLine: false,
      borderWidth: 1,
      hidden:
        activeDataset !== null &&
        activeDataset !== waterSupply[14]?.sprinkler_system,
      datalabels: {
        display: text,
        align: "bottom",
        formatter: (value, context) => {
          const name = value?.name;
          const xValue =
            context?.chart?.data?.datasets[context?.datasetIndex]?.data[
              context?.dataIndex
            ]?.x;
          const yValue =
            context?.chart?.data?.datasets[context?.datasetIndex]?.data[
              context?.dataIndex
            ]?.y;

          return `${xValue}L/min @ ${yValue}kPa`;
        },
      },
    },
  ];

  const data = {
    datasets: getDataSets(),
  };

  const get = async () => {
    try {
      const { data } = await axios?.get(`/assesment/${state?.id}`);
      setPump1(data?.pump1?.pump_performance);
      setPump2(data?.pump2?.pump_performance);
      setPump3(data?.pump3?.pump_performance);
      setPump4(data?.pump4?.pump_performance);
      setWaterSupply(data?.water_supply?.place);
      console?.log("pump", data?.pump1);
    } catch (error) {}
  };

  useEffect(() => {
    get();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Grid
        container
        xs={12}
        sx={{
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "flex-start",
        }}
      >
        <Grid container item xs={12}>
          <Line options={options} data={data} />
        </Grid>
      </Grid>
    </div>
  );
}
