import React, { useState, useEffect } from "react";
import axios from "../../libs/axios";
import { Formik, Form } from "formik";
import TextField from "@mui/material/TextField";
import InputFeild from "../../components/common/NewTextFeild";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import "../Assesment/assesment.css";
import { Button } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const additionalType = [
  "Yes",
  "No",
];

const detectionType = [
  "Smoke Detectors",
  "Heat Detectors",
  "Default Heat Detectors (Sprinklers)",
];

export default function FireDetection({
  formData,
  handlePreviousPage,
  previouscallingBackend,
  setPreviousCallingBackend,
  handleNextPage,
  callingBackend,
  setCallingBackend,
  handleTwoPreviousPage,
}) {
  const { state } = useLocation();
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const inputArr = [
    {
      id: 0,
      building: "",
      additional_detection_required: "",
      detection_type: "",
      coverage: "",
    },
  ];
  const [arr, setArr] = useState(inputArr);
  const [quillbar, setQuillbar] = useState(
    formData?.fire_detection?.fire_detection_comment || ""
  );
  const toolbarOptions = [
    ["bold", "italic", "underline"],
    [{ header: 1 }, { header: 2 }],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }],
  ];
  const module = {
    toolbar: toolbarOptions,
  };
  const changeFormData = (value, name, id) => {
    const objIndex = arr.findIndex((obj) => obj.id === id);
    arr[objIndex][name] = value;

    setArr([...arr]);
  };
  const submit = async (e) => {
    try {
      setCallingBackend(true);
      await axios.put(`/assesment/fire_detection/${state.id}`, {
        fire_detection_page: arr,
        fire_detection_comment: quillbar,
      });
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Fire Detection saved successfully!",
      });
      setTimeout(
        () =>
          setAlert({
            showAlert: false,
            severity: "success",
            message: "Fire Detection saved successfully!",
          }),
        handleNextPage(),
        5000
      );
    } catch (error) {
      if (error.response.data === "Fire Detection already exists") {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Fire Detection already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Fire Detection already exists!",
            }),
          5000
        );
      } else if (error.response.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Unauthorized Access!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Unauthorized Access!",
            }),
          5000
        );
      } else if (error.response.status === 500) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Server Error!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Server Error!",
            }),
          5000
        );
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Fire Detection already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Fire Detection already exists!",
            }),
          5000
        );
      }
    }
  };

  const handleClick = () => {
    setPreviousCallingBackend(true);
     if (
       formData?.assessment?.water_supply_exclude &&
       formData?.assessment?.hysrant_water_supply_exclude
     ) {
       handleTwoPreviousPage();
     } else {
       handlePreviousPage();
     }
  };
  const get = async () => {
    try {
      const { data } = await axios.get(`/assesment/${state.id}`);
      setQuillbar(data?.fire_detection?.fire_detection_comment);
      const item = [...data.construction_page.construction_type].map(
        (item, index) => {
          console.log("item", item);
          if (data.fire_detection.fire_detection_page.length) {
            return {
              id: item?.id,
              building:
                data?.fire_detection?.fire_detection_page[index]?.building ||
                item?.name,
              coverage:
                data?.fire_detection?.fire_detection_page[index]?.coverage ||
                item?.coverage,
              additional_detection_required:
                data?.fire_detection?.fire_detection_page[index]
                  ?.additional_detection_required ||
                item?.additional_detection_required,
              detection_type:
                data?.fire_detection?.fire_detection_page[index]
                  ?.detection_type || item?.detection_type,
            };
          } else {
            return {
              id: item.id,
              building: item.name,
              coverage: "",
              additional_detection_required: "",
              detection_type: "",
            };
          }
        }
      );

      setArr([...item]);
    } catch (error) {}
  };
  useEffect(() => {
    get();
    // eslint-disable-next-line
  }, [formData]);
  return (
    <div>
      <Formik enableReinitialize>
        {({ dirty, isValid }) => {
          return (
            <Form>
              <Grid
                container
                xs={12}
                sx={{
                  padding: "1rem",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="formName">Fire Detection</p>
                <Grid container xs={10} spacing={1}>
                  <Grid container item xs={12} spacing={1}>
                    <Grid item xs={3}>
                      <p className="inputTextH">Building</p>{" "}
                    </Grid>
                    <Grid item xs={3}>
                      <p className="inputTextH">Detection Type</p>{" "}
                    </Grid>
                    <Grid item xs={3}>
                      <p className="inputTextH">
                        Additional Detection Required
                      </p>{" "}
                    </Grid>
                    <Grid item xs={3}>
                      <p className="inputTextH">Coverage (%)</p>{" "}
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} spacing={1}>
                    {arr?.map((item, i) => {
                      return (
                        <>
                          {" "}
                          <Grid container item xs={3} spacing={1}>
                            <Grid item xs={12}>
                              <InputFeild
                                name="building"
                                value={item?.building}
                                component={TextField}
                                variant
                                fullWidth
                                bgColor="#FFFFFF"
                                margin="dense"
                              />{" "}
                            </Grid>
                          </Grid>
                          <Grid item xs={3}>
                            <Autocomplete
                              hiddenLabel
                              id="detection_type"
                              name="detection_type"
                              size="small"
                              value={item.detection_type}
                              options={detectionType}
                              getOptionLabel={(option) => option}
                              onChange={(event, newValue) => {
                                changeFormData(
                                  newValue,
                                  "detection_type",
                                  item.id
                                );
                              }}
                              renderInput={(params) => (
                                <TextField size="small" {...params} />
                              )}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Autocomplete
                              hiddenLabel
                              id="additional_detection_required"
                              name="additional_detection_required"
                              size="small"
                              value={item.additional_detection_required}
                              options={additionalType}
                              getOptionLabel={(option) => option}
                              onChange={(event, newValue) => {
                                changeFormData(
                                  newValue,
                                  "additional_detection_required",
                                  item.id
                                );
                              }}
                              renderInput={(params) => (
                                <TextField size="small" {...params} />
                              )}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <InputFeild
                              name="coverage"
                              value={item.coverage}
                              onChange={(e) =>
                                changeFormData(
                                  e.target.value,
                                  "coverage",
                                  item.id
                                )
                              }
                              component={TextField}
                              variant
                              fullWidth
                              bgColor="#FFFFFF"
                              margin="dense"
                              type="number"
                            />{" "}
                          </Grid>
                        </>
                      );
                    })}
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    spacing={1}
                    style={{ paddingTop: "2rem" }}
                  >
                    <Grid item xs={2}>
                      <p className="inputTextP">Comment</p>{" "}
                    </Grid>
                    <Grid item xs={10} sx={{ marginBottom: "50px" }}>
                      <ReactQuill
                        modules={module}
                        theme="snow"
                        value={quillbar}
                        style={{ marginBottom: "25px", height: "100px" }}
                        onChange={setQuillbar}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    sx={{
                      alignContent: "center",
                      justifyContent: "space-between",
                      marginTop: "2rem",
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#22345B",
                        textTransform: "capitalize",
                        fontFamily: "serif",
                        borderRadius: "8px",
                        ":hover": { backgroundColor: "#22345B" },
                      }}
                      onClick={() => handleClick()}
                      disabled={previouscallingBackend}
                    >
                      {previouscallingBackend ? (
                        <CircularProgress
                          size={25}
                          sx={{ color: "#22345B", margin: "0 5px" }}
                        />
                      ) : (
                        "Previous"
                      )}
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => submit()}
                      disabled={
                        !isValid ||
                        !arr?.every(
                          (item) =>
                            !Object?.entries(item)?.some(
                              ([key, value]) => value === "" || undefined
                            )
                        ) ||
                        callingBackend
                      }
                      sx={{
                        backgroundColor: "#22345B",
                        textTransform: "capitalize",
                        fontFamily: "serif",
                        borderRadius: "8px",
                        ":hover": { backgroundColor: "#22345B" },
                      }}
                    >
                      {callingBackend ? (
                        <CircularProgress
                          size={25}
                          sx={{ color: "#22345B", margin: "0 5px" }}
                        />
                      ) : (
                        "Next"
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
